import Styled from 'styled-components';

const ButtonContainer = Styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
`
const WhiteSpace = Styled.div`
  color: white;
  width: 30%;
`
const ButtonSpace = Styled.div`
  width: auto;
  
`

export { ButtonContainer, WhiteSpace, ButtonSpace }