import { useCallback, useEffect, useRef } from 'react';

export function useTrustedExternalHtml(customHtml) {
  const widgetRef = useRef(null);

  const nodeScriptClone = useCallback((node) => {
    const script = document.createElement('script');
    script.text = node.innerHTML;

    let index = 0;
    const attrs = node.attributes;
    while (index < attrs.length) {
      const attr = attrs[index];
      script.setAttribute(attr.name, attr.value);
      index += 1;
    }
    return script;
  }, []);

  const nodeScriptReplace = useCallback(
    (node) => {
      if (node.tagName === 'SCRIPT') {
        node.parentNode.replaceChild(nodeScriptClone(node), node);
      } else {
        node.childNodes.forEach(nodeScriptReplace);
      }

      return node;
    },
    [nodeScriptClone],
  );

  useEffect(() => {
    const div = document.createElement('div');
    div.innerHTML = customHtml;
    const node = nodeScriptReplace(div);
    if (widgetRef.current != null) {
      widgetRef.current.appendChild(node);
    }
    return () => {
      if (widgetRef.current != null) {
        div.parentNode.removeChild(div);
      }
    };
  }, [customHtml, widgetRef.current, nodeScriptReplace]);

  return {
    widgetRef,
  };
}
