import { API } from "../config/api"
import { DataService } from "../config/dataService/dataService"


class PendingsBoardService {

  static async getBoardSettings() {
    const { data } = await DataService.get(API.pendingsBoard.settings)
    return {
      boardSettings: data.boardSettings,
    }
  }

  static async updateBoardSettings(newData) {
    const { data } = await DataService.patch(API.pendingsBoard.settings, newData)
    return {
      boardSettings: data.boardSettings
    }
  }
}


export { PendingsBoardService }