import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { Popover, Space, Tooltip } from 'antd';
import { ModalEditList } from './ModalEditList';
import { ImportListModal } from './ImportListModal';
import useCustomListTable from './hooks/useCustomListTable';
import { CustomListTableActions } from './CustomListTableActions';
import { Button } from '../../../../components/buttons/buttons';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { useNotifications } from '../../../../hooks/useNotifications';
import { DataTable } from '../../../../components/dataTable/data-table';


export const CustomListTable = () => {
  const { translate: t } = useDictionaryTranslation();
  const { showConfirmNotification } = useNotifications();
  const [selectedList, setSelectedList] = useState();
  const [isOpencustomListsModal, setOpencustomListModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const { deleteList, tableData, reloadData, getTableData, isLoading } = useCustomListTable();

  const openCustomListModal = () => {
    setOpencustomListModal(true);
  };

  const closecustomListsModal = (value) => {
    setOpencustomListModal(false);
    setSelectedList();
    if (value) reloadData();
  };

  const getOpenImportListModal = (record) => () => {
    setShowImportModal(true);
    setSelectedList(record)
  }

  const closeImportListModal = () => {
    setShowImportModal(false)
  }

  const getDeleteListAction = (customList) => () => {
    showConfirmNotification({
      title: t('areYouSure'),
      content: t('recordWillBeRemoved', { recordDescription: customList.name }),
      onOk: async () => {
        deleteList(customList._id);
      },
    });
  };

  const getEditCustomListAction = (customList) => () => {
    setSelectedList(customList)
    openCustomListModal();
  };

  const getShowExamType = (list) => () => {
    setSelectedList({ ...list, showDetails: true });
  };

  const columns = [
    {
      key: 'title',
      title: t('list'),
      dataIndex: 'title',
    },
    {
      key: 'description',
      title: t('description'),
      dataIndex: 'description',
      align: 'center',
    },
    {
      key: 'actions',
      align: 'center',
      title: t('actions'),
      render: (_text, record) => {
        return (
          <Space size="small" style={{ padding: '0px' }}>
            <Popover
              trigger="focus"
              content={
                <CustomListTableActions
                  customListId={record._id}
                  openImportListModal={getOpenImportListModal(record)}
                  getShowExamType={getShowExamType(record)}
                  editCustomListItem={getEditCustomListAction(record)}
                  deleteItemList={getDeleteListAction(record)}
                />
              }
              placement="bottom"
              overlayInnerStyle={{ padding: '0px' }}
            >
              <Button outlined type="secondary" style={{ border: 'none' }}>
                {' '}
                <FeatherIcon icon="more-horizontal" />{' '}
              </Button>
            </Popover>
            <Button outlined type="secondary" style={{ border: 'none', alignItems: 'center' }}>
              <Link to={`/customLists/${record._id}`}>
                <FeatherIcon icon="search" />
              </Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  if (selectedList?.showDetails) return <Redirect push to={`/customLists/${selectedList._id}`} />;

  return (
    <Cards headless>
      <DataTable
        rowKey="_id"
        columns={columns}
        isLoading={isLoading}
        onChange={getTableData}
        tableData={tableData}
        headerData={{
          show: true,
          searchPlaceholder: t('customList'),
          itemsName: t('customLists'),
          actions: (
            <>
              <Tooltip title={t('create')}>
                <Button
                  type="primary"
                  onClick={openCustomListModal}
                  htmlType="button"
                  className="add-service"
                  icon={<PlusOutlined />}
                />
              </Tooltip>
            </>
          ),
        }}
      />
      <ModalEditList
        show={isOpencustomListsModal}
        handleClose={closecustomListsModal}
        customListId={selectedList?._id}
      />
      <ImportListModal
        customListId={selectedList?._id}
        show={showImportModal}
        handleClose={closeImportListModal}

      />
    </Cards>
  );
};

export default CustomListTable;
