import { useCallback, useState, useEffect } from 'react';
import { UsersService } from '../../../services/usersService';

const useCountUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [countUsers, setCountUsers] = useState({ data: [] });

  const getCountUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { profileCounts } = await UsersService.getCountUsers();
      setCountUsers({
        data: profileCounts,
      });
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getCountUsers();
  }, [getCountUsers]);

  return {
    getCountUsers,
    isLoading,
    hasError,
    countUsers,
  };
};

export default useCountUser;
