import { useEffect } from 'react';

export function useDebouncedFunction(debouncedFunction, delay) {
  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedFunction();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedFunction, delay]);
}

export function useDebouncedEffect(effect, delay, deps) {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect(deps);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [effect, delay, ...deps]);
}