import { useCallback, useState, useEffect } from 'react';
import { ProfileTypes } from '../../../config/constants/userConstants';
import { UsersService } from '../../../services/usersService';
import { getSchedulingBlocks } from '../../../utility/selfSchedulingUtils';

const useUser = ({ userId, profileId }) => {
  const [userData, setUserData] = useState({
    schedulingTimes: getSchedulingBlocks([]),
    profile: {
      specialities: [],
    },
  });
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countUsers, setCountUsers] = useState();

  const getUserData = useCallback(async () => {
    if (userId == null || !userId?.length) return;
    setIsLoading(true);
    try {
      const data = await UsersService.getById(userId);
      const newUserData = {
        ...data.user,
        schedulingTimes: getSchedulingBlocks(data.schedulingTimes ?? []),
        resourcesToAdmin: data.resourcesToAdmin ?? [],
        servicesTypesAssigned: data.servicesTypesAssigned ?? [],
        scheduleLocks: data.scheduleLocks ?? [],
      };
      setUserData(newUserData);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const getCountUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { profileCounts } = await UsersService.getUsers(profileId);
      setCountUsers({
        data: profileCounts,
      });
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  });

  const getProfileData = (data) => {
    return {
      identification: data.identification,
      name: data.name,
      idProfile: data.idProfile,
      cellular: data.cellular,
      phone: data.phone,
      idsHeadquarters: data.idsHeadquarters,
      // interested
      companies: data.companies,
      typePetition: data.typePetition,
      idTypePetition: data.idTypePetition,
      canCreatePetitions: data.canCreatePetitions,
      // technician
      specialities: data.specialities,
      idZones: data.idZones,
      confirmationChangeStatus: data.confirmationChangeStatus,
      canCreateServices: data.canCreateServices,
      canViewServicesHistory: data.canViewServicesHistory,
      enableAvailabilitySchedule: data.enableAvailabilitySchedule,
      schedulingTimes: data.schedulingTimes,
      scheduleLocks: data.scheduleLocks,
      // operator
      administrateServices: data.administrateServices,
      administrateClients: data.administrateClients,
      administrateAdvancedAvailability: data.administrateAdvancedAvailability,
      adminAllUsers:
        data.adminAllUsers || (data.idProfile === ProfileTypes.OPERATOR.id && !data.idResourcesToAdmin?.length),
      idResourcesToAdmin: data.idResourcesToAdmin,
      administrateInventory: data.administrateInventory,
      seeAllServices: data.seeAllServices,
      administrateTask: data.administrateTask,
      seeAllTask: data.seeAllTask,
      administrateWorkflows: data.administrateWorkflows,
      seeAllWorkflows: data.seeAllWorkflows,
      servicesTypesAssigned: data.servicesTypesAssigned,
      canChangeServiceStatus: data.canChangeServiceStatus,
      canCreateIncidentNotes: data.canCreateIncidentNotes,
      canEditFinalCustomer: data.canEditFinalCustomer,
      canManageCustomers: data.canManageCustomers,
      isMandatoryGPS: data.isMandatoryGPS,
    };
  };

  const createUser = async (data) => {
    setIsLoading(true);
    try {
      const newUserData = {
        username: data.username,
        email: data.email,
        newPassword: data.password,
        profile: getProfileData(data),
      };
      await UsersService.createUser(newUserData);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (data) => {
    setIsLoading(true);
    try {
      const newUserData = {
        username: data.username,
        email: data.email,
        profile: getProfileData(data),
      };
      await UsersService.updateUser(userId, newUserData);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async (data) => {
    const idUser = data.userId ?? userId;

    setIsLoading(true);
    try {
      const newUserData = {
        newPassword: data.password,
      };
      await UsersService.updatePasswordUser(idUser, newUserData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return {
    userData,
    createUser,
    updateUser,
    hasError,
    isLoading,
    getCountUsers,
    countUsers,
    updatePassword,
  };
};

export default useUser;
