import actions from './actions';

const { DICTIONARY_BEGIN, DICTIONARY_SUCCESS, DICTIONARY_ERR } = actions;

const initState = {
  loading: false,
  data: {
    commonTerms: {
      service: 'Servicio',
      servicePlural: 'Servicios',
      customer: 'Cliente',
      customerPlural: 'Clientes',
      contact: 'Contacto',
      contactPlural: 'Contactos',
      finalCustomer: 'Cliente final',
      finalCustomerPlural: 'Clientes finales',
      headquarters: 'Sede',
      headquartersPlural: 'Sedes',
    },
    profileNames: {
      operator: 'Operador',
      operatorPlural: 'Operadores',
      technician: 'Técnico',
      technicianPlural: 'Técnicos',
    },
  },
};

const DictionaryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DICTIONARY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DICTIONARY_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DICTIONARY_ERR:
      return {
        ...state,
        err,
        loading: false,
      };
    default:
      return state;
  }
};

export default DictionaryReducer;
