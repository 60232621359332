import actions from './actions';
import { getItem } from '../../utility/localStorageControl';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  CHECK_AUTH_BEGIN,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_ERR,
  AUTH_CHECK,
  ASSIGN_MODULES,
} = actions;

const initState = {
  currentUser: {},
  checkLogin: !!getItem('access_token'),
  login: false,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        checkLogin: false,
        currentUser: data.currentUser,
        accountData: data.accountData,
        permissions: data.permissions,
        error: null,
        login: true,
        loading: false,
        accountModules: data.accountModules,
      };
    case LOGIN_ERR:
      return {
        ...state,
        checkLogin: false,
        login: false,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHECK_AUTH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHECK_AUTH_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case CHECK_AUTH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case AUTH_CHECK:
      return {
        ...state,
        checkLogin: true,
      };
    case ASSIGN_MODULES:
      return {
        ...state,
        accountModules: data.accountModules,
      };
    default:
      return state;
  }
};
export default AuthReducer;
