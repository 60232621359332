import { Space } from "antd";
import styled from "styled-components";

const TextUsers = styled.span `
color: #9BA3B6;
font-size: 100%;
& .count{margin-right: 3px;}
`

const SpaceText = styled(Space)`
margin-bottom: 2%;
margin-left: 2.1%;
`

export { TextUsers, SpaceText }