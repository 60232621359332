import React, { useEffect, useCallback } from 'react';
import { Button, Col, Form, PageHeader, Row, Spin } from 'antd';
import { useExternalWidgets } from './hooks/useExternalWidgets';
import { EditorInputContainer } from './styled';
import { Main } from '../../container/styled';
import { Cards } from '../cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { CodeEditor } from '../codeEditor/codeEditor';
import { FormWrapperPage } from '../formWrapper/form-wrapper';

const WidgetsExternalCreate = () => {
  const { translate: t } = useDictionaryTranslation();
  const [form] = Form.useForm();
  const { widgets, loading, saveWidgets } = useExternalWidgets();

  useEffect(() => {
    form.setFieldsValue({
      widgetHtml: widgets.html,
    });
  }, [widgets]);

  const onFinish = useCallback((values) => {
    saveWidgets(values.widgetHtml);
  }, []);

  return (
    <>
      <PageHeader title={t('widgetsExternal')} ghost />
      <Main>
        <Cards headless>
          <Spin spinning={loading}>
            <FormWrapperPage>
              <Form name="createWidget" form={form} layout="vertical" onFinish={onFinish}>
                <Row>
                  <Col md={24} sm={24}>
                    <EditorInputContainer>
                      <Form.Item name="widgetHtml" rules={[{ required: true, message: t('templateRequired') }]}>
                        <CodeEditor />
                      </Form.Item>
                    </EditorInputContainer>
                  </Col>
                </Row>
                <Row justify="end">
                  <Button form="createWidget" htmlType="submit" type="primary" style={{ marginRight: '16px' }}>
                    {t('save')}
                  </Button>
                </Row>
              </Form>
            </FormWrapperPage>
          </Spin>
        </Cards>
      </Main>
    </>
  );
};

export default WidgetsExternalCreate;
