const actions = {
  GET_LANDING_PAGE_DATA_BEGIN: 'GET_LANDING_PAGE_DATA_BEGIN',
  GET_LANDING_PAGE_DATA_SUCCESS: 'GET_LANDING_PAGE_DATA_SUCCESS',
  GET_LANDING_PAGE_DATA_ERR: 'GET_LANDING_PAGE_DATA_ERR',
  getLandingPageBegin: () => {
    return {
      type: actions.GET_LANDING_PAGE_DATA_BEGIN,
    };
  },
  getLandingPageSuccess: data => {
    return {
      type: actions.GET_LANDING_PAGE_DATA_SUCCESS,
      data,
    };
  },
  getLandingPageErr: err => {
    return {
      type: actions.GET_LANDING_PAGE_DATA_ERR,
      err,
    };
  },
};

export default actions;
