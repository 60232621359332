import Styled from 'styled-components';
import { Upload } from 'antd';

const StyledUpload = Styled(Upload)`
  display: flex;
  width: 100%;
  flex-direction: column;
  .ant-upload {
    display: flex;
    justify-content: ${props => props.direction ?? 'left'}};
  }
  .ant-upload .anticon svg {
      color: white;
  }
`;

export { StyledUpload };
