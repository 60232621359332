import React from 'react';
import PropTypes from 'prop-types';
import { Space, Switch } from 'antd';

export const CustomSwitch = ({ checked, onChange, checkedText, unCheckedText, disabled = false }) => {
  return (
    <Space size="middle">
      <span>{unCheckedText}</span>
      <Switch checked={checked} onChange={onChange} disabled={disabled} />
      <span>{checkedText}</span>
    </Space>
  );
};

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  checkedText: PropTypes.string,
  unCheckedText: PropTypes.string,
  disabled: PropTypes.bool,
};
