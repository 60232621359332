import React, { createContext } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { useListNodesContextValue } from '../hooks/useListNodesContextValue';

const listNodesContext = createContext({
  nodes: [],
  setListNodes: async () => {},
  getListNodes: async () => {},
  isLoading: true,
});

const ListNodesProvider = ({ listId, children }) => {
  const listNodesContextValue = useListNodesContextValue(listId);
  return (
    <listNodesContext.Provider value={listNodesContextValue}>
      <Spin spinning={listNodesContextValue.isLoading}>{children}</Spin>
    </listNodesContext.Provider>
  );
};

ListNodesProvider.propTypes = {
  listId: PropTypes.string,
  children: PropTypes.node,
};

export { ListNodesProvider, listNodesContext };
