import { FileUploadsService } from './fileUploadService';
import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

export class SupportChatsService {
  static async getSupsportChats({ search, status, statusList, page, pageSize }) {
    const { data } = await DataService.get(API.supportChats.root, { search, statusList, status, page, pageSize });
    return {
      supportChats: data.supportChats,
      total: data.total,
    };
  }

  static async searchMessages({ search, status, page, pageSize }) {
    const { data } = await DataService.get(`${API.supportChats.root}/${API.supportChats.chatMessages}`, {
      search,
      status,
      page,
      pageSize,
    });
    return {
      supportChats: data.supportChats,
      total: data.total,
    };
  }

  static async getSupportChatById(id) {
    const { data } = await DataService.get(`${API.supportChats.root}/${id}`);
    return {
      supportChat: data.supportChat,
    };
  }

  static async getSignedPost({ idSupportChat, filename, contentType }) {
    const params = { idSupportChat, filename, contentType };
    const routes = API.supportChats;
    const { data } = await DataService.get(`${routes.root}/${routes.attachmentSignedPost}`, params);
    return {
      signedPost: data.signedPost,
      fileKey: data.fileKey,
    };
  }

  static async uploadAttachment(idSupportChat, file) {
    const { signedPost, fileKey } = await SupportChatsService.getSignedPost({
      idSupportChat,
      filename: file.name,
      contentType: file.type,
    });
    const result = await FileUploadsService.uploadSignedFile(signedPost, file);
    return {
      resultUploadFile: result,
      fileKey,
    };
  }

  static async getUpdates() {
    const { data } = await DataService.get(`${API.supportChats.root}/${API.supportChats.updates}`);
    return {
      updates: data.updates,
    };
  }
}
