import { PageHeader, Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import useCustomList from './hooks/useCustomList';
import ListNodes from '../listNodes/ListNodes';
import { Cards } from '../../../../components/cards/frame/cards-frame';
import { Main } from '../../../../container/styled';
import { ListNodesProvider } from '../listNodes/context/listNodesContext';

const ExamType = () => {
  const { customListId } = useParams();
  const { customListData, isLoading } = useCustomList(customListId);

  return (
    <>
      <PageHeader title={customListData.title} ghost />
      <Main>
        <Cards headless>
          {isLoading ? (
            <div className="spin">
              <Spin spinning={isLoading} />
            </div>
          ) : (
            <ListNodesProvider listId={customListId}>
              <ListNodes customListId={customListId}/>
            </ListNodesProvider>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default ExamType;
