import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from '../dropdown-select/DropdownSelect';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { SkillsService } from '../../services/skillsService';

export const SelectSkills = ({ value, onChange, multiple = false, getSkills }) => {
  const { translate: t } = useDictionaryTranslation();
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectSkill = item => {
    if (multiple) {
      onChange(item);
      return;
    }
    let selectedOption;
    if (item) selectedOption = skills.find(s => s._id === item.value);
    onChange(selectedOption);
  };

  const getItems = useCallback(async search => {
    setLoading(true);
    try {
      const { specialties } = await getSkills({ search });
      setSkills(specialties);
    } catch (error) {
      setSkills([]);
    } finally {
      setLoading(false);
    }
  }, [getSkills]);

  const getOptionData = useCallback(skill => {
    return {
      value: skill._id,
      label: skill.name,
    };
  }, []);

  return (
    <DropdownSelect
      placeholderText={t('skillPlaceholder')}
      isLoading={loading}
      onChange={selectSkill}
      options={skills}
      value={value}
      onSearch={getItems}
      getOptionData={getOptionData}
      multiple={multiple}
    />
  );
};

SelectSkills.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  getSkills: PropTypes.func,
};

SelectSkills.defaultProps = {
  getSkills: SkillsService.getSkills,
  multiple: false,
};