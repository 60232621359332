import actions from './actions';

const {
  CUSTOM_HTML_BEGIN,
  CUSTOM_HTML_SUCCESS,
  CUSTOM_HTML_ERR,
  REMOVE_CUSTOM_HTML,
} = actions;

const initState = {
  customHtml: '',
};

/**
 *
 * @todo impure state mutation/explaination
 */
const CustomHtmlReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    case CUSTOM_HTML_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CUSTOM_HTML_SUCCESS:
      return {
        ...state,
        checkLogin: false,
        customHtml: data.customHtml,
        loading: false,
      };
    case CUSTOM_HTML_ERR:
      return {
        ...state,
        customHtml: '',
        loading: false,
      };
    case REMOVE_CUSTOM_HTML:
      return {
        ...state,
        customHtml: '',
        loading: false,
      };
    default:
      return state;
  }
};
export default CustomHtmlReducer;
