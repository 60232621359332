import { Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import FormUserDetails from './FormUserDetails';
import FormUserOptions from './FormUserOptions';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';

export const UserDetailsTabs = () => {
  const history = useHistory();
  const { translate: t } = useDictionaryTranslation();
  const { userId } = useParams();
  const [currentTab, setCurrentTab] = useState('user');

  const currentRoute = useRouteMatch();

  const tabs = useMemo(
    () => [
      {
        key: 'user',
        title: t('user'),
        component: () => <FormUserDetails />,
      },
      {
        key: 'options',
        title: t('options'),
        component: () => <FormUserOptions />,
      },
    ],
    [],
  );
  const updateHistory = ({ id = userId, tab = currentTab }) => {
    const nextPath = currentRoute.url.replace(':userId', id).replace(':tab', tab);
    history.push(nextPath);
  };
  const onChangeTab = (value) => {
    setCurrentTab(value);
    updateHistory(value);
  };
  return (
    <Tabs onChange={onChangeTab} destroyInactiveTabPane type="card" activeKey={currentTab}>
      {tabs.map((tab) => (
        <Tabs.TabPane key={tab.key} tab={t(tab.title)}>
          <tab.component />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
