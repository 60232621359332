import { authHeader, client } from './client';

class DataService {
  static get(path = '', params, signal) {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
      params,
      signal,
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static putFile(path = '', file) {
    const formData = new FormData();
    formData.append('data', file);
    return client({
      method: 'PUT',
      url: path,
      data: formData,
      headers: { ...authHeader() },
    });
  }
}

export { DataService };
