import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Col, Input, Row, Button } from 'antd';
import { ScheduleBlockContainer, ScheduleBlockTextContainer } from '../../Styles';

export const ScheduleBlockItem = ({ from, to, disabled, onChange, onRemove }) => {
  const onChangeField = (key) => (event) => {
    onChange({ [key]: event.target.value });
  };

  return (
    <ScheduleBlockContainer>
      <Row>
        <Col xs={10}>
          <Input type="time" value={from} disabled={disabled} onChange={onChangeField('from')} />
        </Col>
        <Col xs={1}>
          <ScheduleBlockTextContainer>-</ScheduleBlockTextContainer>
        </Col>
        <Col xs={10}>
          <Input type="time" value={to} disabled={disabled} onChange={onChangeField('to')} />
        </Col>
        <Col xs={3}>
          <ScheduleBlockTextContainer>
            <Button type="text" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />
          </ScheduleBlockTextContainer>
        </Col>
      </Row>
    </ScheduleBlockContainer>
  );
};

ScheduleBlockItem.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};
