import { useCallback, useEffect, useState } from "react"
import { PendingsBoardService } from "../../../services/pendingsBoardService";
import { useNotifications } from "../../../hooks/useNotifications";


export const usePendingsBoard = () => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [settingData, setSettingData] = useState();

  const getPendingsSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await PendingsBoardService.getBoardSettings();
      setSettingData(data.boardSettings);
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRetrievingRecords',
      });
    } finally {
      setIsLoading(false);
    }
  }, [setSettingData, showErrorNotification]);

  const updateBoardSettings = useCallback(async (updateData) => {
    setIsLoading(true);
    try {
      await PendingsBoardService.updateBoardSettings(updateData);
      showSuccessNotification({
        title: 'success',
        description: 'successUpdatingRecord',
      });
      getPendingsSettings()
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorUpdatingRecord',
      })
    } finally {
      setIsLoading(false);
    }
  }, [showSuccessNotification, showErrorNotification])

  useEffect(() => {
    getPendingsSettings();
  }, [getPendingsSettings])

  return {
    isLoading,
    settingData,
    updateBoardSettings,
    getPendingsSettings
  }

}