import React from 'react';
import PropTypes from 'prop-types';
import { TableOutlined } from '@ant-design/icons';
import { StyledUpload, } from './styles';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Button } from '../buttons/buttons';

export const FileUploadButton = ({
  text = 'selectFile',
  fileList,
  setFileList,
  color,
  maxCount,
  fullWidth = false,
  icon,
  onBeforeUpload,
  buttonDirection
}) => {
  const { translate: t } = useDictionaryTranslation();

  const unselectFile = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = async file => {
    if (onBeforeUpload) {
      const result = await onBeforeUpload(file);
      if (!result) return false;
    }
    setFileList([file]);
    return false;
  };

  return (
    <StyledUpload
      direction={buttonDirection}
      onRemove={unselectFile}
      beforeUpload={beforeUpload}
      fileList={fileList}
      maxCount={maxCount}
      listType="picture"
    >
      <Button type={color} icon={icon || <TableOutlined />} block={fullWidth}>
        {t(text)}
      </Button>
    </StyledUpload>
  );
};

FileUploadButton.propTypes = {
  text: PropTypes.string,
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
  maxCount: PropTypes.number,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  onBeforeUpload: PropTypes.func,
  buttonDirection: PropTypes.oneOf(['right', 'left']),
};
