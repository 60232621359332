import actions from './actions';

const { GET_LANDING_PAGE_DATA_BEGIN, GET_LANDING_PAGE_DATA_SUCCESS, GET_LANDING_PAGE_DATA_ERR } = actions;

const accountValidationBegin = state => {
  return {
    ...state,
    loading: true,
  };
};

const accountValidationSuccess = (state, { data }) => {
  const { landingPageData } = data;
  return {
    ...state,
    loading: false,
    error: null,
    landingPageData,
  };
};

const accountValidationError = (state, { err }) => {
  return {
    ...state,
    error: err,
    loading: false,
    landingPageData: null,
  };
};

const actionHandlers = {
  [GET_LANDING_PAGE_DATA_BEGIN]: accountValidationBegin,
  [GET_LANDING_PAGE_DATA_SUCCESS]: accountValidationSuccess,
  [GET_LANDING_PAGE_DATA_ERR]: accountValidationError,
};

export default actionHandlers;
