import { API } from '../config/api';
import { dictionaryTermsKeys } from '../config/constants/dictionaryConstants';
import { DataService } from '../config/dataService/dataService';

class DictionariesService {
  static async getDictionary() {
    const { data } = await DataService.get(API.dictionaries.root);
    return DictionariesService.transformDictionary(data.dictionary)
  }

  static transformDictionary({ commonTerms, profileNames }) {
    const dictionary = {
      commonTerms: DictionariesService.getCommonTerms(commonTerms),
      profileNames: DictionariesService.getProfileNames(profileNames),
    };
    return dictionary;
  }

  static async getDictionaryRaw() {
    const { data } = await DataService.get(API.dictionaries.root);
    return {
      dictionary: data.dictionary,
    };
  }

  static async updateCommonTerms(termData) {
    const dataDictionary = {
      idTerm: termData.idTerm,
      description: termData.singular,
      plural: termData.plural,
      idColor: termData.idColor,
    };
    const { data } = await DataService.patch(API.dictionaries.root + API.dictionaries.commonTerms, dataDictionary);
    return {
      termData: data.termData,
    };
  }

  static async updateProfileName(termData) {
    const dataDictionary = {
      idProfile: termData.idProfile,
      description: termData.singular,
      plural: termData.plural,
      idColor: termData.idColor,
    };
    const { data } = await DataService.patch(API.dictionaries.root + API.dictionaries.profileNames, dataDictionary);
    return {
      termData: data.termData,
    };
  }

  static getCommonTerms(terms) {
    return DictionariesService.getTerms(terms, dictionaryTermsKeys.commonTerms, 'idTerm');
  }

  static getProfileNames(terms) {
    return DictionariesService.getTerms(terms, dictionaryTermsKeys.profileNames, 'idProfile');
  }

  static getTerms(dictionaryTerms, termKeys, keyId) {
    const terms = {};
    dictionaryTerms.forEach((term) => {
      const termId = term[keyId];
      const keys = termKeys[termId];
      if (keys) {
        terms[keys.key] = term.description;
        terms[keys.keyPlural] = term.plural;
      }
    });
    return terms;
  }
}

export { DictionariesService };
