import actions from './actions';
import { GadgetsHtmlService } from '../../services/gadgetsHtmlService';

const { getCustomHtmlBegin, getCustomHtmlErr, getCustomHtmlSuccess } = actions;

const addCustomHtml = () => {
  return async dispatch => {
    try {
      dispatch(getCustomHtmlBegin());
      const { gadgets } = await GadgetsHtmlService.getGadgetsHtml();
      return dispatch(getCustomHtmlSuccess({ customHtml: gadgets.html }));
    } catch (err) {
      return dispatch(getCustomHtmlErr(err));
    }
  };
};

const removeCustomHtml = () => {
  return async dispatch => {
    try {
      return dispatch(removeCustomHtml());
    } catch (err) {
      return dispatch(getCustomHtmlErr(err));
    }
  };
}

export { addCustomHtml, removeCustomHtml };
