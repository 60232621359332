import actions from './actions';
import { CustomReportsSettingsService } from '../../services/customReportsSettingsService';

const { customReportsBegin, customReportsSuccess, customReportsErr } = actions;

const getCustomReports = () => {
  return async (dispatch) => {
    try {
      dispatch(customReportsBegin());
      const customReports = await CustomReportsSettingsService.getCountCustomReports();
      dispatch(customReportsSuccess(customReports));
    } catch (error) {
      dispatch(customReportsErr(error));
    }
  };
};

export { getCustomReports};
