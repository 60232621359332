import React from 'react';
import PropTypes from 'prop-types';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { EditorContainer } from './style';

export const CodeEditor = ({ onChange, value = '' }) => {
  const onChangeEditor = React.useCallback(
    editorValue => {
      if (onChange) onChange(editorValue);
    },
    [onChange],
  );
  return (
    <EditorContainer>
      <CodeMirror
        value={value}
        height="100%"
        minHeight="350px"
        width='100%'
        theme={dracula}
        extensions={[html()]}
        onChange={onChangeEditor}
      />
    </EditorContainer>
  );
};

CodeEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
