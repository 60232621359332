import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from '../../dropdown-select/DropdownSelect';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { CompaniesService } from '../../../services/companiesService';

export const SelectCompany = ({ selectedCompany, onChange, idServiceType, multiple, idHeadquarter }) => {
  const { translate: t } = useDictionaryTranslation();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectCompany = (item) => {
    if (multiple) {
      onChange(item);
      return;
    }
    let selectedOption;
    if (item) selectedOption = companies.find((s) => s._id === item.value);
    onChange(selectedOption);
  };

  const getCompanies = useCallback(
    async (search) => {
      setLoading(true);
      try {
        const params = { search, isServiceCreation: true };
        if (idServiceType) params.idServiceType = idServiceType;
        if (idHeadquarter) params.idHeadquarter = idHeadquarter;
        const { companies: companiesList } = await CompaniesService.getAll(params);
        setCompanies(companiesList);
        const canSetSingleValue = multiple ? !selectedCompany?.length : !selectedCompany;
        if(canSetSingleValue && idHeadquarter && companiesList?.length === 1){
          const selectedItem = {
            ...companiesList[0],
            data: companiesList[0],
            value: companiesList[0]._id,
            label: companiesList[0].name,
          };
          if (multiple) {
            selectCompany([selectedItem]);
          } else {
            selectCompany(selectedItem);
          }
        }
      } catch (error) {
        console.error(error);
        setCompanies([]);
      } finally {
        setLoading(false);
      }
    },
    [idServiceType, idHeadquarter, selectedCompany],
  );

  const getOptionData = useCallback((company) => {
    return {
      data: company,
      value: company._id,
      label: company.name,
    };
  }, []);

  useEffect(() => {
    getCompanies()
  },
  [getCompanies]);

  return (
    <DropdownSelect
      placeholderText={t('customerPlaceholder')}
      isLoading={loading}
      onChange={selectCompany}
      options={companies}
      value={selectedCompany}
      onSearch={getCompanies}
      getOptionData={getOptionData}
      multiple={multiple}
    />
  );
};

SelectCompany.propTypes = {
  selectedCompany: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  ]),
  idServiceType: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  idHeadquarter: PropTypes.string,
};
