import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from '../dropdown-select/DropdownSelect';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';

export const SelectHeadquartersBase = ({
  value,
  onChange,
  multiple,
  getData,
  idsHeadquarters,
  useFilteredIds,
  autoSelect = true,
}) => {
  const { translate: t } = useDictionaryTranslation();
  const [headquarters, setHeadquarters] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectHeadquarter = (item) => {
    if (multiple) {
      onChange(item);
      return;
    }
    let selectedOption;
    if (item) selectedOption = item.data ?? headquarters.find((s) => s._id === item.value);
    onChange(selectedOption);
  };

  const getHeadquarters = useCallback(
    async (search) => {
      setLoading(true);
      try {
        const { headquarters: data } = await getData({
          search,
          idsHeadquarters: useFilteredIds ? (idsHeadquarters?.length ? idsHeadquarters : [null]) : null,
        });
        const canSetSingleValue = multiple ? !value?.length : !value;
        if (autoSelect && data?.length === 1 && canSetSingleValue) {
          const selectedItem = {
            ...data[0],
            value: data[0]._id,
            name: data[0].name,
            label: data[0].name,
            data: data[0],
          };
          if (multiple) {
            selectHeadquarter([selectedItem]);
          } else {
            selectHeadquarter(selectedItem);
          }
        }
        setHeadquarters(data);
      } catch (error) {
        setHeadquarters([]);
      } finally {
        setLoading(false);
      }
    },
    [idsHeadquarters, useFilteredIds, value],
  );

  const getOptionData = useCallback((headquarter) => {
    return {
      value: headquarter._id,
      label: headquarter.name,
      data: headquarter,
    };
  }, []);

  return (
    <DropdownSelect
      placeholderText={t('headquarterPlaceholder')}
      isLoading={loading}
      onChange={selectHeadquarter}
      options={headquarters}
      value={value}
      onSearch={getHeadquarters}
      getOptionData={getOptionData}
      multiple={multiple}
    />
  );
};

SelectHeadquartersBase.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  getData: PropTypes.func,
  idsHeadquarters: PropTypes.array,
  useFilteredIds: PropTypes.bool,
  autoSelect: PropTypes.bool,
};
