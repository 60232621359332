import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getNumberParam, getQueryParams, getTextParam } from '../../../utility/utility';

export const useTableData = ({
  total = 0,
  page = 1,
  pageSize = 0,
  data = [],
  search = '',
  useQueryParams = false,
  queryParamsKeys = {},
} = {}) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = getQueryParams(location.search);
  const { pageKey = 'page', searchKey = 'search' } = queryParamsKeys;
  const [tableData, setTableData] = useState({
    search: useQueryParams ? getTextParam(queryParams[searchKey]) : search,
    page: useQueryParams ? getNumberParam(queryParams[pageKey]) : page,
    total,
    pageSize,
    data,
  });

  const updateHistory = useCallback(
    ({ pageValue = tableData.page, searchValue = tableData.search } = {}) => {
      if (!useQueryParams) return;
      const newQueryParams = [`${pageKey}=${pageValue}`, `${searchKey}=${searchValue}`];
      history.push({ search: `?${newQueryParams.join('&')}`, state: { pageValue, searchValue } });
    },
    [tableData.page, tableData.search, history, pageKey, searchKey, useQueryParams],
  );

  const updateTableFilters = useCallback(
    ({ page: pageValue = tableData.page, search: searchValue = tableData.search }) => {
      setTableData(prev => ({
        ...prev,
        page: pageValue,
        search: searchValue,
      }));
      updateHistory({ pageValue, searchValue });
    },
    [tableData.page, tableData.search, updateHistory],
  );

  const updateTableData = useCallback(tableValues => {
    setTableData(prev => ({ ...prev, ...tableValues }));
  }, []);

  useEffect(() => {
    if (!useQueryParams) return;
    const params = getQueryParams(location.search);
    setTableData(prev => ({
      ...prev,
      page: getNumberParam(params[pageKey]),
      search: getTextParam(params[searchKey]),
    }));
  }, [location.search, useQueryParams, pageKey, searchKey]);

  return useMemo(
    () => ({
      ...tableData,
      updateTableFilters,
      setTableData: updateTableData,
      updateHistory,
    }),
    [updateTableFilters, updateTableData, updateHistory, tableData],
  );
};
