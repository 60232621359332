import { FileUploadsService } from './fileUploadService';
import { API } from '../config/api';
import { SelfSchedulingDataService } from '../config/dataService/selfSchedulingDataService';

class AppointmentSelfSchedulingService {
  static SCHEDULE_NOT_AVAILABLE = 'SCHEDULE_NOT_AVAILABLE';

  static async validateAccount(accountId, professional) {
    const bodyData = {
      accountId,
      professional,
    };
    const { data } = await SelfSchedulingDataService.post(
      `${API.appointmentSelfScheduling.root}/${API.appointmentSelfScheduling.token}`,
      bodyData,
    );
    return {
      token: data.token,
      accountData: data.account,
      professionalData: data.professional,
    };
  }

  static async getLandingPageData() {
    const { data } = await SelfSchedulingDataService.get(
      `${API.appointmentSelfScheduling.root}/${API.appointmentSelfScheduling.landingPageData}/`,
    );
    return {
      landingPageData: data.landingPageData,
    };
  }

  static async getAppointmentTypes({ search, page, pageSize, customerId }) {
    const queryParams = { search, page, pageSize, idCustomer: customerId };
    const { data } = await SelfSchedulingDataService.get(
      `${API.appointmentSelfScheduling.root}/${API.appointmentSelfScheduling.appointmentTypes}`,
      queryParams,
    );
    return {
      appointmentTypes: data.appointmentTypes,
      total: data.total,
    };
  }

  static async getAppointmentTotal() {
    const { data } = await SelfSchedulingDataService.get(
      `${API.appointmentSelfScheduling.root}/${API.appointmentSelfScheduling.appointmentTypes}/total`,
    );

    return {
      total: data.total,
    };
  }

  static async getUnavailableDates({ appointmentType, headquarter, startDate, endDate }) {
    const routes = API.appointmentSelfScheduling;
    const params = { startDate, headquarter, endDate };
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${routes.appointmentTypes}/${appointmentType}/${routes.unavailableDates}`,
      params,
    );
    return {
      unavailableDates: data.unavailableDates,
    };
  }

  static async getSelfScheduleFields() {
    const { data } = await SelfSchedulingDataService.get(
      `${API.appointmentSelfScheduling.root}/${API.appointmentSelfScheduling.patienSelfScheduleFields}`,
    );

    return {
      selfSchedulingFields: data.selfScheduleFields,
    };
  }

  static async getScheduleBlocks({ appointmentType, date, signal, professional, headquarter }) {
    const routes = API.appointmentSelfScheduling;
    const params = { professional, date, headquarter };
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${routes.appointmentTypes}/${appointmentType}/${routes.scheduleBlocks}`,
      params,
      signal,
    );
    return {
      scheduleTimes: data.scheduleTimes,
    };
  }

  static async getHeadquarters({ search, page, idsHeadquarters }) {
    const params = {
      search,
      page,
      idsHeadquarters,
    };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.headquarters}`, params);
    return {
      headquarters: data.headquarters,
    };
  }

  static async getDepartments({ search, page }) {
    const params = {
      search,
      page,
    };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.departments}`, params);
    return {
      departments: data.departments,
    };
  }

  static async getHealthInsurances({ search, page }) {
    const params = {
      search,
      page,
    };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.healthInsurances}`, params);
    return {
      healthInsurances: data.healthInsurances,
    };
  }

  static async getPatientByIdentification({ identification, identificationType }) {
    const params = {
      identification,
      identificationType,
    };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.patient}`, params);
    return {
      patient: data.patient,
    };
  }

  static async getBloodTypes({ page = 1, search = '' }) {
    const params = { page, search };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.bloodTypes}`, params);
    return {
      bloodTypes: data.bloodTypes,
      total: data.total,
      pageSize: data.pageSize,
      search,
      page,
    };
  }

  static async getCountries({ page = 1, search = '' }) {
    const params = { page, search };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.countries}`, params);
    return {
      countries: data.countries,
      total: data.total,
      pageSize: data.pageSize,
      search,
      page,
    };
  }

  static async getCities({ page = 1, search = '', stateCode }) {
    const queryParams = { page, search, stateCode };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.cities}`, queryParams);
    return {
      cities: data.cities,
      total: data.total,
      pageSize: data.pageSize,
      search,
      page,
    };
  }

  static async getRegimes({ page = 1, search = '' }) {
    const queryParams = { page, search };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.regimes}`, queryParams);

    return {
      regimes: data.regimes,
      total: data.total,
      pageSize: data.pageSize,
      search,
      page,
    };
  }

  static async createAppointment({
    idTypeService,
    date,
    patientData,
    time,
    assignedUserId,
    headquarterId,
    extendedWebInfo,
    checkPriority,
    customerId,
  }) {
    const appointmentData = {
      idTypeService,
      assignedUserId,
      headquarterId,
      date,
      patientData,
      time,
      extendedWebInfo,
      checkPriority,
      customerId,
    };
    const { data } = await SelfSchedulingDataService.post(API.appointmentSelfScheduling.root, appointmentData);
    return {
      appointment: data.appointment,
    };
  }

  static async getProfessionals({ search, page, headquarter, specialities }) {
    const params = {
      search,
      page,
      headquarter,
      specialties: specialities,
    };
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.professionals}`, params);
    return {
      professionals: data.professionals,
    };
  }

  static async getDictionary() {
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.dictionary}`);
    return {
      dictionary: data.dictionary,
    };
  }

  static async getFormData(id) {
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.extendedForms}/${id}`);
    return data.incidentForm;
  }

  static async getListRoot(id) {
    const routes = API.appointmentSelfScheduling;
    const customListsRoutes = routes.customLists;
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${customListsRoutes.root}/${id}/${customListsRoutes.listRoot}`,
    );
    return {
      listRoot: data.listRoot,
    };
  }

  static async getListItems(listId, parents = [], page = 1) {
    const routes = API.appointmentSelfScheduling;
    const customListsRoutes = routes.customLists;
    const params = {
      page,
      parents,
    };
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${customListsRoutes.root}/${listId}/${customListsRoutes.listItems}`,
      params,
    );
    return {
      listItems: data.listItems,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async getSignedPost({ path, filename, contentType }) {
    const params = { path, filename, contentType };
    const routes = API.appointmentSelfScheduling;
    const fileUploadsRoutes = routes.fileUploads;
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${fileUploadsRoutes.root}/${fileUploadsRoutes.signedPost}`,
      params,
    );
    return {
      signedPost: data.signedPost,
      fileKey: data.fileKey,
    };
  }

  static async uploadFile(path, file) {
    const { signedPost, fileKey } = await AppointmentSelfSchedulingService.getSignedPost({
      path,
      filename: file.name,
      contentType: file.type,
    });
    const result = await FileUploadsService.uploadSignedFile(signedPost, file);
    return {
      resultUploadFile: result,
      fileKey,
    };
  }

  static async getAssets({ page, search, assetType }) {
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.assets}`, {
      page,
      search,
      assetType,
    });
    return {
      assets: data.assets,
      total: data.total,
      pageSize: data.pageSize,
      page,
    };
  }

  static async getCustomers({ page, search }) {
    const params = {
      page,
      search,
    }
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${routes.root}/${routes.customers}`, params);
    return {
      customers: data.customers,
    }
  }

  static async validateReCaptchaV2(token) {
    const routes = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(
      `${routes.root}/${routes.reCaptcha.root}/${routes.reCaptcha.validateV2}`,
      { token },
    );
    return {
      isValid: data.isValid,
    };
  }

  static async getTerms() {
    const { root, terms, legalData } = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${root}/${legalData}/${terms}`);

    return {
      terms: data,
    };
  }

  static async getDataTreatment() {
    const { root, dataTreatment, legalData } = API.appointmentSelfScheduling;
    const { data } = await SelfSchedulingDataService.get(`${root}/${legalData}/${dataTreatment}`);
    return {
      dataTreatment: data,
    };
  }
}

export { AppointmentSelfSchedulingService };
