import { Col, Form, Modal, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { Button } from '../../../../components/buttons/buttons';
import constants from '../../../../config/api/constants';
import { FileUploadButton } from '../../../../components/file-upload-button/file-upload-button';
import { useImportCustomList } from '../customLists/hooks/useImportCustomList';

const ModalImportItem = ({ show, handleClose, customListId }) => {
  const { translate: t } = useDictionaryTranslation();
  const [successImport, setSuccessImport] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { importList } = useImportCustomList();
  const { idAcount } = useSelector((state) => {
    return {
      idAcount: state.auth.accountData._id,
    };
  });
  const { Link } = Typography;

  const uploadImportList = useCallback(
    async (file) => {
      try {
        importList(customListId, file, idAcount);
        setSuccessImport(true);
      } catch (error) {
        console.error(error);
      }
    },
    [importList, idAcount, customListId],
  );

  const handleClosseModal = () => {
    handleClose({ successImport });
    setSuccessImport(false);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title="importListItems"
        centered
        width="35%"
        height="50%"
        onCancel={handleClosseModal}
        destroyOnClose
        visible={show}
        footer={null}
      >
        <Form layout="vertical">
          <Row>
            <Col xs={24}>
              <Form.Item label={t('downloadBaseTemplate')}>
                <Link href={constants.customListImportTemplate}>
                  <Button type="secondary" icon={<DownloadOutlined />}>
                    {t('downloadTemplate')}
                  </Button>
                </Link>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={t('uploadImportsTemplateMessage')}>
                <FileUploadButton
                  buttonDirection="left"
                  text={t('uploadTemplate')}
                  color="secondary"
                  maxCount={1}
                  style={{ marginRight: '20px !important' }}
                  fileList={fileList}
                  setFileList={setFileList}
                  onBeforeUpload={uploadImportList}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

ModalImportItem.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  customListId: PropTypes.string,
};

export default ModalImportItem;
