import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectFromConstant } from '../../../components/dropdown-select/SelectFromConstant';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { ProfileTypes } from '../../../config/constants/userConstants';

const selectableUsers = [ProfileTypes.TECHNICIAN.id, ProfileTypes.INTERESTED.id, ProfileTypes.OPERATOR.id];

export const SelectUserProfile = ({ value, onChange, multiple , disabled}) => {
  const { translate: t } = useDictionaryTranslation();
  const options = useMemo(() => {
    return Object.values(ProfileTypes)
      .filter((option) => selectableUsers.includes(option.id))
      .map((option) => ({ ...option, label: t(option.name), value: option.id }));
  }, [t]);

  const selectedValue = value && options.find((v) => v.value === value.value);

  return (
    <SelectFromConstant
      value={selectedValue}
      onChange={onChange}
      constantObject={options}
      placeholderText={t('selectTypeUserPlaceholder')}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

SelectUserProfile.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};
