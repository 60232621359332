import React, { useState } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useListNodes } from './hooks/useListNodes';
import { ModalEditNode } from './ModalEditNode';
import { CustomListItems } from './CustomListItems';
import { useListNodesActions } from './hooks/useListNodesActions';
import ModalImportItem from './ModalImportItem';
import { Button } from '../../../../components/buttons/buttons';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';

const ListNodes = ({ customListId }) => {
  const { translate: t } = useDictionaryTranslation();
  const [isOpenNodeListModal, setOpenNodeListModal] = useState(false);
  const [isOpenImportModal, setOpenImportModal] = useState(false);
  const { nodes, setListNodes, getListNodes } = useListNodes();
  const { addNode } = useListNodesActions({ nodes, saveNodesChanges: setListNodes });

  const openNodeListModal = () => {
    setOpenNodeListModal(true);
  };

  const closeNodesModal = () => {
    setOpenNodeListModal(false);
  };

  const openImportModal = () => {
    setOpenImportModal(true);
  };

  const closeImportModal = ({ successImport = false } = {}) => {
    if (successImport) {
      getListNodes();
    }
    setOpenImportModal(false);
  };

  const saveNode = (nodeId, nodeValues) => {
    if (nodeId == null) {
      addNode(nodeValues);
    }
  };

  return (
    <>
      <Row justify="end" gutter={10}>
        <Col>
          <Button type="secondary" onClick={openImportModal}>
            {t('import')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={openNodeListModal}>
            {t('addListItem')}
          </Button>
        </Col>
      </Row>
      <CustomListItems nodes={nodes} saveNodesChanges={setListNodes} />
      {isOpenNodeListModal ? (
        <ModalEditNode show={isOpenNodeListModal} handleClose={closeNodesModal} saveNode={saveNode} />
      ) : null}
      <ModalImportItem handleClose={closeImportModal} show={isOpenImportModal} customListId={customListId} />
    </>
  );
};

export default ListNodes;

ListNodes.propTypes = {
  customListId: PropTypes.string,
};
