import React, { useCallback, useEffect } from 'react';
import { Table } from 'antd';
import { MailFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DataTableHeader } from './data-table-header';
import { useDebouncedFunction } from '../../hooks/useDebouncedFunction';

export const DataTable = ({
  columns,
  rowKey,
  headerData,
  onChange,
  tableData,
  isLoading,
  showPagination = true,
  reduceSearch = false,
}) => {
  const getData = useCallback(
    ({ page = tableData.page, search = tableData.search } = {}) => {
      if (onChange) {
        onChange({ page, search });
      }
    },
    [tableData.page, tableData.search, onChange],
  );

  const onChangePagination = ({ current: currentPage }) => {
    if (currentPage !== tableData.page) {
      tableData.updateTableFilters({ page: currentPage });
    }
  };

  const updateSearchValue = (value) => {
    const params = {
      page: 1,
      search: value,
    };
    tableData.updateTableFilters(params);
  };

  useEffect(() => {
    if (tableData.total && !tableData.data.length) {
      const page = Math.ceil(tableData.total / tableData.pageSize);
      tableData.updateTableFilters({ page });
    }
  }, [tableData]);

  const onChangeFilters = useCallback(() => {
    getData({ page: tableData.page, search: tableData.search });
  }, [tableData.page, tableData.search, getData]);

  useDebouncedFunction(onChangeFilters, 100);

  return (
    <>
      {headerData.show ? (
        <DataTableHeader
          currentDataSize={tableData.data.length}
          totalSize={tableData.total}
          updateSearchValue={updateSearchValue}
          searchValue={tableData.search}
          actions={headerData.actions}
          searchPlaceholder={headerData.searchPlaceholder}
          enabledSearch={headerData.enabledSearch}
          itemsName={headerData.itemsName}
          totalInPage={tableData.data.length}
          totalRecords={tableData.total}
          isLoading={isLoading}
          reduceSearch={reduceSearch}
        />
      ) : null}
      <Table
        rowKey={rowKey}
        loading={isLoading}
        dataSource={tableData.data}
        locale={{ emptyText: <MailFilled style={{ fontSize: '30px' }} /> }}
        columns={columns}
        rowClassName={(record) => {
          if(record.isDisabled)
            return 'ant-table-row-disabled';
          return ''
        }}
        pagination={
          showPagination
            ? {
                showSizeChanger: false,
                current: tableData.page,
                total: tableData.total,
                pageSize: tableData.pageSize,
              }
            : false
        }
        onChange={onChangePagination}
      />
    </>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      align: PropTypes.string,
      title: PropTypes.string,
      render: PropTypes.func,
    }),
  ),
  rowKey: PropTypes.string,
  showPagination: PropTypes.bool,
  onChange: PropTypes.func,
  headerData: PropTypes.shape({
    actions: PropTypes.object,
    show: PropTypes.bool,
    itemsName: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    enabledSearch: PropTypes.bool,
  }),
  tableData: PropTypes.shape({
    data: PropTypes.array,
    page: PropTypes.number,
    search: PropTypes.string,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    setTableData: PropTypes.func,
    updateHistory: PropTypes.func,
    updateTableFilters: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  reduceSearch: PropTypes.bool,
  queryParamsKeys: PropTypes.shape({
    pageKey: PropTypes.string,
    searchKey: PropTypes.string,
  }),
};
