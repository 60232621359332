import { useNotifications } from "../../../../../hooks/useNotifications";
import { CustomListsService } from "../../../../../services/customListService"


export const useImportCustomList = () => {
  const { showSuccessNotification, showErrorNotification } = useNotifications();
 
  const importList = async (customListId, file, idAccount) => {
    try {
      await CustomListsService.importCustomList({idAccount, listId: customListId, listItemsFile: file });
      showSuccessNotification({
        title: 'success',
        description: 'successUploadingFile',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorUploadingFile',
      });
      throw error;
    }
  }

  return {
    importList,
  }

}

