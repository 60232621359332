import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import AuthLayout from '../../layout/authentication/Index';

const Login = lazy(() => import('../../views/login/SignIn'));

const UnauthenticatedRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`} component={Login} />
      <Redirect to="/" />
    </Switch>
  );
};

export default AuthLayout(UnauthenticatedRoutes);
