import { useContext } from 'react';
import { listNodesContext } from '../context/listNodesContext';

export const useListNodes = () => {
  const { isLoading, nodes, setListNodes, getListNodes } = useContext(listNodesContext);

  return {
    nodes,
    setListNodes,
    getListNodes,
    isLoading,
  };
};
