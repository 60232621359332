import { API } from "../config/api";
import { DataService } from "../config/dataService/dataService";

export class GadgetsHtmlService {
  static async getGadgetsHtml() {
    const { data } = await DataService.get(API.gadgetsHtml.root);
    return {
      gadgets: data.gadgets,
    }
  }

  static async updateGadgetsHtml(html) {
    const params = {
      html,
    };
    const { data } = await DataService.patch(API.gadgetsHtml.root, params);
    return {
      gadgets: data.gadgets,
    }
  }
}
