const actions = {
  DICTIONARY_BEGIN: 'DICTIONARY_BEGIN',
  DICTIONARY_SUCCESS: 'DICTIONARY_SUCCESS',
  DICTIONARY_ERR: 'DICTIONARY_ERR',

  dictionaryBegin: () => {
    return {
      type: actions.DICTIONARY_BEGIN,
    };
  },

  dictionarySuccess: data => {
    return {
      type: actions.DICTIONARY_SUCCESS,
      data,
    };
  },

  dictionaryErr: err => {
    return {
      type: actions.DICTIONARY_BEGIN,
      err,
    };
  },
};

export default actions;
