import { lazy } from 'react';
import { ProfileTypes } from './constants/userConstants';
import { ListUsers } from '../views/users/ListUsers';
import { UserDetails } from '../views/userDetails/UserDetails';
import ExamType from '../views/customList/components/customLists/Customlist';
import CustomList from '../views/customList/CustomList';
import WidgetsExternal from '../views/widgetsExternal/WidgetsExternal';
import Api from '../views/api/Api';
import { ChatUpdateDecorator } from '../views/chat/menuItem/chatUpdatesDecorator';

const ChatApp = lazy(() => import('../views/chat/ChatApp2'));
const Asset = lazy(() => import('../views/assets/Assets'));
const Account = lazy(() => import('../views/account/components/Account'));
const AssetDetails = lazy(() => import('../views/assetDetails/AssetDetails'));
const AssetTypes = lazy(() => import('../views/assetTypes/assetTypes'));
const SurveyGroup = lazy(() => import('../views/surveys/surveysGroup/components/SurveysGroup'));
const SurveyHistory = lazy(() => import('../views/surveys/surveysHistory/components/SurveyHistory'));
const EditSurveyHistory = lazy(() =>
  import('../views/surveys/surveysHistory/components/editSurveyHistory/EditSurveyHistory'),
);
const SurveyHistoryDetails = lazy(() =>
  import('../views/surveys/surveysHistory/components/surveyHistoryDetails/components/SurveyHistoryDetails'),
);
const IncidentTypesOptions = lazy(() => import('../views/serviceTypesOptions/IncidentTypesOptions'));
const TypesNote = lazy(() => import('../views/noteTypes/NoteTypes'));
const Answersfrequents = lazy(() => import('../views/Answersfrequent/components/Answersfrequent'));
const NoteTypesItems = lazy(() => import('../views/noteTypesItems/NoteTypesItems'));
const CancellationReassonsItems = lazy(() => import('../views/cancellationReassonsItems/CancellationReassonsItems'));
const FinalCustomerTags = lazy(() => import('../views/finalCustomerTagsItems/FinalCustomerTagsItems'));
const FinalCustomerDetails = lazy(() => import('../views/FinalCustomerDetail/FinalCustomerDetails'));
const HeadquarterDetails = lazy(() => import('../views/headquartersDetail/HeadquartersDetails'));
const LegalPageSettings = lazy(() => import('../views/legal/LegalPageSettings'));
const IntegrationWhatsappPage = lazy(() => import('../views/integrationWhatsapp/IntegrationWhatsappPage'));
const whatsappCreateMessage = lazy(() => import('../views/messengerService/whatsapp/components/whatsappCreateMessage'));
const Whatsapp = lazy(() => import('../views/messengerService/whatsapp/components/Whatsapp'));
const PdfSettings = lazy(() => import('../views/pdfSettings/PdfSettings'));
const EditPdf = lazy(() => import('../views/pdfSettings/tabs/personalized/editPdf/EditPdf'));
const whatsappMessageViewDetails = lazy(() =>
  import('../views/messengerService/whatsapp/components/whatsappMessageViewDetails'),
);

const EditIncidentTypesNotifications = lazy(() =>
  import('../views/serviceTypesNotificationDetails/EditIncidentTypeNotifications'),
);
const IncidentTypesNotifications = lazy(() => import('../views/serviceTypesNotifications/IncidentTypesNotifications'));
const IncidentTypes = lazy(() => import('../views/IncidentTypes/IncidentTypes'));
const ServicesHistory = lazy(() => import('../views/servicesHistory/ServicesHistory'));
const ServicesHistoryDetails = lazy(() => import('../views/servicesHistoryDetails/ServicesDetail'));
const PendingsBoard = lazy(() => import('../views/pendingsBoard/PendingsBoard'));
const SelfScheduling = lazy(() => import('../views/selfScheduling/selfScheduling'));
const AppointmentPageSettings = lazy(() => import('../views/appointmentsPageSettings/AppointmentPageSettings'));
const Dashboard = lazy(() => import('../views/dashboard/Dashboard'));
const Reports = lazy(() => import('../views/reports/Reports'));
const Modules = lazy(() => import('../views/modules/Modules'));
const Accounts = lazy(() => import('../views/accounts/Accounts'));
const Calendar = lazy(() => import('../views/calendar/Calendar'));
const CustomReportsSettingsTable = lazy(() =>
  import('../views/customReportsSettings/components/CustomReportsSettingsTable'),
);
const CustomReportsSettingsDetail = lazy(() =>
  import('../views/customReportsSettings/components/CustomReportsSettingsDetail'),
);
const CustomerDetails = lazy(() => import('../views/CustomerDetails/CustomerDetails'));
const Customers = lazy(() => import('../views/Customers/Customers'));
const FinalCustomers = lazy(() => import('../views/FinalCostumers/FinalCustomers'));
const Skills = lazy(() => import('../views/skills/Skills'));
const ListDictionary = lazy(() => import('../views/dictionary/ListDictionary'));
const IncidentTypeDetails = lazy(() => import('../views/IncidentTypeDetails/IncidentTypeDetails'));
const Headquarters = lazy(() => import('../views/Headquarters/Headquarters'));
const Personalization = lazy(() => import('../views/Personalization/Personalization'));
const Availability = lazy(() => import('../views/availability/Availability'));
const AwaitingList = lazy(() => import('../views/awaitingList/AwaitingList'));
const Sequences = lazy(() => import('../views/sequences/Sequences'));
// const CustomList = lazy(() => import('../views/customList/CustomList'));

export const defaultPath = '/auth/login';

export const Routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    icon: 'bar-chart-2',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: Dashboard,
  },
  {
    path: '/accounts',
    name: 'accounts',
    icon: 'bar-chart-2',
    profiles: [ProfileTypes.MASTER.id],
    checkPermissions: (permissions) => !permissions.manageAccounts,
    component: Accounts,
  },
  {
    path: '/calendar/:tab?',
    name: 'calendar',
    icon: 'calendar',
    profiles: [ProfileTypes.OPERATOR.id, ProfileTypes.ADMIN.id, ProfileTypes.TECHNICIAN.id],
    component: Calendar,
    collapseMenu: true,
  },
  {
    path: '/incident-type',
    redirect: true,
    name: 'incident-type',
    profiles: [ProfileTypes.ADMIN.id],
    component: IncidentTypeDetails,
  },
  {
    path: '/customers',
    name: 'customers',
    icon: 'home',
    moduleName: 'customers',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: Customers,
  },
  {
    path: '/support-chat/:chatId?',
    name: 'supportChat',
    icon: 'info',
    moduleName: 'supportChat',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: ChatApp,
    decorator: ChatUpdateDecorator,
  },
  {
    path: '/customers/:id/:tab?',
    redirect: true,
    name: 'customers',
    icon: 'shopping-bag',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: CustomerDetails,
  },
  {
    path: '/final-customer/',
    name: 'finalCustomers',
    icon: 'users',
    moduleName: 'finalCustomers',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: FinalCustomers,
  },
  {
    path: '/final-customer/:id/:tab?',
    redirect: true,
    name: 'finalCustomers',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: FinalCustomerDetails,
  },
  {
    path: '/incident-type/:id/notifications',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [ProfileTypes.ADMIN.id],
    component: IncidentTypesNotifications,
  },
  {
    path: '/incident-type/:incidentTypeId/edit-notifications/:notificationId?',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [ProfileTypes.ADMIN.id],
    component: EditIncidentTypesNotifications,
  },
  {
    path: '/incident-type/:id/options/:tab?/',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [ProfileTypes.ADMIN.id],
    component: IncidentTypesOptions,
  },
  {
    path: '/services-history/',
    name: 'servicesHistory',
    icon: 'list',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id, ProfileTypes.TECHNICIAN.id],
    component: ServicesHistory,
  },
  {
    path: '/pendings-board/',
    name: 'pendingsBoard',
    moduleName: 'pendingsBoard',
    newTab: true,
    icon: 'trello',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id, ProfileTypes.TECHNICIAN.id],
    component: PendingsBoard,
  },
  {
    isContainer: true,
    name: 'surveys',
    // redirect: true,
    moduleName: 'surveys',
    path: '/surveys',
    icon: 'copy',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    children: [
      {
        path: '/surveys-group',
        name: 'surveysGroup',
        profiles: [ProfileTypes.ADMIN.id],
        component: SurveyGroup,
      },
      {
        path: '/survey-history/',
        name: 'surveyHistory',
        profiles: [ProfileTypes.ADMIN.id],
        component: SurveyHistory,
      },
    ],
  },
  {
    path: '/awaiting-list/',
    name: 'awaitingList',
    icon: 'share',
    moduleName: 'awaitingList',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id, ProfileTypes.TECHNICIAN.id],
    component: AwaitingList,
  },
  {
    path: '/services-detail/:id/:tab?',
    redirect: true,
    name: 'servicesHistory',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id, ProfileTypes.TECHNICIAN.id],
    component: ServicesHistoryDetails,
  },
  {
    path: '/headquarters/:id/:tab?',
    redirect: true,
    name: 'headquarters',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: HeadquarterDetails,
  },
  {
    path: '/incident-type-details/:incidentTypeId/:tab?',
    redirect: true,
    name: 'editIncidentType',
    profiles: [ProfileTypes.ADMIN.id],
    component: IncidentTypeDetails,
  },
  {
    path: '/incident-type/:incidentTypeId/self-scheduling/',
    name: 'autoScheudling',
    icon: 'calendar',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: SelfScheduling,
  },
  {
    path: '/availability/',
    name: 'availability',
    icon: 'clock',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: Availability,
    moduleName: 'advancedAvailability',
    checkPermissions: (permissions) => {
      return permissions.administrateAdvancedAvailability;
    },
  },
  {
    isContainer: true,
    name: 'config',
    path: '/settings',
    icon: 'settings',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    children: [
      {
        path: '/users',
        name: 'users',
        icon: 'person',
        profiles: [ProfileTypes.ADMIN.id],
        component: ListUsers,
      },
      {
        path: '/headquarters/',
        name: 'headquarters',
        icon: 'layout',
        profiles: [ProfileTypes.ADMIN.id],
        component: Headquarters,
      },
      {
        path: '/skills/',
        name: 'skills',
        icon: 'layout',
        profiles: [ProfileTypes.ADMIN.id],
        component: Skills,
      },
      {
        path: '/incident-type/',
        name: 'serviceTypes',
        icon: 'database',
        profiles: [ProfileTypes.ADMIN.id],
        component: IncidentTypes,
      },
      {
        path: '/appointments-landing-page/:tab?',
        defaultPath: '/appointments-landing-page/info',
        name: 'selfScheduling',
        icon: 'layout',
        profiles: [ProfileTypes.ADMIN.id],
        moduleName: 'selfSchedulingPage',
        component: AppointmentPageSettings,
      },

      {
        path: '/dictionary/:tab?',
        name: 'dictionary',
        icon: 'book-open',
        profiles: [ProfileTypes.ADMIN.id],
        component: ListDictionary,
      },
      {
        path: '/customLists',
        name: 'customLists',
        icon: '',
        profiles: [ProfileTypes.ADMIN.id],
        component: CustomList,
      },
      {
        path: '/note-types',
        name: 'notesTypes',
        icon: '',
        profiles: [ProfileTypes.ADMIN.id],
        component: TypesNote,
      },
      {
        path: '/frequent-answers',
        name: 'answersFrequent',
        icon: '',
        profiles: [ProfileTypes.ADMIN.id],
        component: Answersfrequents,
      },
      {
        path: '/custom-report-settings',
        name: 'customReportSettings',
        icon: '',
        profiles: [ProfileTypes.MASTER.id],
        component: CustomReportsSettingsTable,
      },
      {
        path: '/asset-types',
        name: 'assetTypes',
        icon: '',
        moduleName: 'asset',
        profiles: [ProfileTypes.ADMIN.id],
        component: AssetTypes,
      },
      {
        path: '/sequences/',
        name: 'sequences',
        profiles: [ProfileTypes.ADMIN.id],
        component: Sequences,
      },
      {
        path: '/pdf-settings/:tab?',
        name: 'pdfConfigText',
        icon: '',
        profiles: [ProfileTypes.ADMIN.id],
        component: PdfSettings,
        moduleName: 'pdfSettings',
      },
      {
        path: '/custom-pdf-format/:id?',
        name: 'pdfCreate',
        redirect: true,
        profiles: [ProfileTypes.ADMIN.id],
        component: EditPdf,
        moduleName: 'pdfSettings',
      },
    ],
  },
  {
    isContainer: true,
    name: 'messengerService',
    path: '/messengerService',
    icon: 'message-circle',
    moduleName: 'messaging',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    children: [
      {
        path: '/whatsapp/',
        name: 'whatsapp',
        profiles: [ProfileTypes.ADMIN.id],
        component: Whatsapp,
        moduleName: 'messaging',
      },
      {
        path: '/whatsapp/details/:id?',
        redirect: true,
        name: 'whatsappMessageViewDetails',
        profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
        component: whatsappMessageViewDetails,
      },
      {
        path: '/whatsapp/cretaeMessage/',
        redirect: true,
        name: 'whatsappMessageViewDetails',
        profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
        component: whatsappCreateMessage,
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    icon: 'file-text',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: Reports,
  },
  {
    isContainer: true,
    name: 'administration',
    path: '/administration',
    icon: 'grid',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    children: [
      {
        path: '/modules/',
        name: 'modules',
        profiles: [ProfileTypes.MASTER.id],
        component: Modules,
      },
      {
        path: '/widgets-external/',
        name: 'widgetsExternal',
        profiles: [ProfileTypes.ADMIN.id],
        component: WidgetsExternal,
      },
      {
        path: '/personalization',
        name: 'personalization',
        icon: '',
        profiles: [ProfileTypes.ADMIN.id],
        component: Personalization,
        checkPermissions: (permissions) => {
          return permissions.enableAccountCustomization;
        },
      },
      {
        path: '/api/',
        name: 'api',
        profiles: [ProfileTypes.ADMIN.id],
        component: Api,
        checkPermissions: (permissions) => {
          return permissions.enableAccountAPI;
        },
      },
      {
        path: '/integration-whatsapp/',
        name: 'integrationWhatsapp',
        profiles: [ProfileTypes.ADMIN.id],
        component: IntegrationWhatsappPage,
      },
      {
        path: '/legal/:tab?',
        name: 'legal',
        profiles: [ProfileTypes.ADMIN.id],
        component: LegalPageSettings,
      },
      {
        path: '/account',
        name: 'account',
        profiles: [ProfileTypes.ADMIN.id],
        component: Account,
      },
    ],
  },
  {
    path: '/user/:userId?/:tab?',
    name: 'userDetails',
    icon: 'person',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: UserDetails,
  },
  {
    path: '/survey-history-edit/:surveyId?',
    name: 'EditSurveyHistory',
    redirect: true,
    icon: '',
    profiles: [ProfileTypes.ADMIN.id],
    component: EditSurveyHistory,
  },
  {
    path: '/survey-history-details/:surveyId?',
    name: 'surveyHistoryDetails',
    redirect: true,
    icon: '',
    profiles: [ProfileTypes.ADMIN.id],
    component: SurveyHistoryDetails,
  },

  {
    path: '/custom-report-settings-detail/:customReportId?',
    name: 'customReportSettingsDetail',
    redirect: true,
    profiles: [ProfileTypes.MASTER.id],
    component: CustomReportsSettingsDetail,
  },
  {
    path: '/customLists/:customListId?',
    name: 'ExamType',
    icon: 'person',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: ExamType,
  },
  {
    path: '/service-notes-types',
    name: 'noteTypeDetails',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: NoteTypesItems,
  },
  {
    path: '/service-cancellation-reassons',
    name: 'noteTypeDetails',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: CancellationReassonsItems,
  },
  {
    path: '/final-customer-tags',
    name: 'finalCustomerTags',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: FinalCustomerTags,
  },
  {
    path: '/asset/:id?',
    name: 'asset',
    redirect: true,
    profiles: [ProfileTypes.ADMIN.id],
    component: Asset,
  },
  {
    path: '/assets/:id/:tab?',
    redirect: true,
    name: 'assetsDetails',
    profiles: [ProfileTypes.ADMIN.id, ProfileTypes.OPERATOR.id],
    component: AssetDetails,
  },
];
