import React from 'react';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { ActionItem, ContentActions } from '../../../IncidentTypes/components/Styles';

export const CustomListTableActions = ({ deleteItemList, editCustomListItem, openImportListModal }) => {
  const { translate: t } = useDictionaryTranslation();

  return (
    <ContentActions>
      <li>
        <ActionItem onClick={openImportListModal}>
          <UploadOutlined />
          <span>{t('import')}</span>
        </ActionItem>
      </li>

      <li>
        <ActionItem onClick={editCustomListItem}>
          <EditOutlined />
          <span>{t('edit')}</span>
        </ActionItem>
      </li>

      <li>
        <ActionItem onClick={deleteItemList}>
          <DeleteOutlined />
          <span>{t('delete')}</span>
        </ActionItem>
      </li>
    </ContentActions>

  )
}

CustomListTableActions.propTypes = {
  deleteItemList: PropTypes.func,
  editCustomListItem: PropTypes.func,
  openImportListModal: PropTypes.func,
};