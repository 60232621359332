import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class AuthService {
  static async login(email, password) {
    const { data } = await DataService.post(API.auth.login, { email, password });
    return {
      user: data.user,
      token: data.token,
      accountData: data.accountData,
      accountModules: data.accountModules
    };
  }

  static async getCurrentUser() {
    const { data } = await DataService.get(API.auth.currentUser);
    return {
      user: data.user,
      accountData: data.accountData,
      accountModules: data.accountModules,
    };
  }
}

export { AuthService };
