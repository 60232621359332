import { Button, Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useUser from '../../../users/hook/useUser';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { CustomSwitch } from '../../../selfScheduling/components/form/editScheduleBlocks/customSwitch';
import { useNotifications } from '../../../../hooks/useNotifications';

const FormUserOptions = () => {
  const { translate: t } = useDictionaryTranslation();
  const { userId } = useParams();
  const { updateUser, userData } = useUser({ userId });
  const { profile } = userData;
  const [form] = Form.useForm();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const enableMobileAppSettings = useSelector((state) => state.auth.accountModules.mobileAppSettings);

  const onSubmit = async () => {
    const values = form.getFieldValue();
    const data = {
      canCreatePetitions: profile.canCreatePetitions,
      username: profile.username?.replace(/\W/g, ''),
      email: profile.emails,
      password: '',
      identification: profile.identification,
      name: profile.name,
      idProfile: profile.idProfile,
      cellular: profile.phone,
      assignedCompany: profile.assignedCompany,
      // canViewServicesHistory: profile.allowToViewHistory,
      enableAvailabilitySchedule: profile.enableSchedulingTimes,
      // canCreateServices: profile.createServicesFromMobile,
      administrateAdvancedAvailability: profile.administrateAdvancedAvailability,
      phone: profile.phone,
      schedulingTimes: profile.schedulingTimes,
      scheduleLocks: profile.scheduleLocks,
      idResourcesToAdmin: profile.resources?.map((resource) => resource.value),
      editInfoCustomers: profile.editInfoCustomers,
      administrateServices: profile.administrateServices,
      seeAllServices: profile.seeAllServices,
      servicesTypesAssigned: profile.servicesTypesAssigned?.map((option) => option.value),
      administrateTask: profile.administrateTask,
      seeAllTask: profile.seeAllTask,
      administrateWorkflows: profile.administrateWorkflows,
      seeAllWorkflows: profile.seeAllWorkflows,
      administrateClients: profile.administrateClients,
      specialities: profile?.specialities?.map((skill) => skill._id),
      companies: profile?.companies?.map((company) => company.value),
      idsHeadquarters: profile.idsHeadquarters.map((headquarter) => headquarter._id),
      canChangeServiceStatus: profile.canChangeServiceStatus,
      canCreateServices: values.canCreateServices,
      adminAllUsers: values.adminAllUsers,
      canViewServicesHistory: values.canViewServicesHistory,
      canEditFinalCustomer: values.canEditFinalCustomer,
      canManageCustomers: values.canManageCustomers,
      isMandatoryGPS: values.isMandatoryGPS,
    };
    try {
      if (userId != null) {
        await updateUser(data);
        showSuccessNotification({
          title: 'success',
          description: 'successUpdatingRecord',
        });
      }
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: userId == null ? 'errorCreatingRecord' : 'errorUpdatingRecord',
      });
    }
  };

  useEffect(() => {
    if (userId) {
      form.setFieldsValue({
        canCreateServices: profile.canCreateServices,
        adminAllUsers: profile.adminAllUsers,
        canViewServicesHistory: profile.canViewServicesHistory,
        canEditFinalCustomer: profile.canEditFinalCustomer,
        canManageCustomers: profile.canManageCustomers,
        isMandatoryGPS: profile.isMandatoryGPS,
      });
    }
  }, [userId, userData]);
  return (
    <>
      <Form form={form} name="createUserForm" onFinish={onSubmit} layout="vertical">
        <Row>
          {enableMobileAppSettings ? (
            <>
              <Col xs={12}>
                <Form.Item name="canCreateServices" label={t('createServicesTextOptions')} valuePropName="checked">
                  <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item name="canManageCustomers" label={t('historyServiceTextSwitch')} valuePropName="checked">
                  <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item name="canViewServicesHistory" label={t('createCustomerTextOptions')} valuePropName="checked">
                  <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="canEditFinalCustomer"
                  label={t('createFinalCustomerTextOptions')}
                  valuePropName="checked"
                >
                  <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item name="isMandatoryGPS" label={t('gpsTextswitch')} valuePropName="checked">
                  <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col md={24}>
              <p>{t('thereAreNoOtherOptionsEnabled')}</p>
            </Col>
          )}
        </Row>
        <Row justify="end">
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t('save')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};
export default FormUserOptions;
