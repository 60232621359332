import { days } from '../config/constants/selfScheduling';

const getSchedulingBlocks = (schedulingTimes = []) => {
  return days.map(day => {
    const scheduleDay = schedulingTimes.find(d => d.dayId === day.value);
    return {
      dayId: day.value,
      label: day.label,
      blocks: scheduleDay?.blocks ?? [],
      checked: !!scheduleDay?.checked,
    };
  });
};

export { getSchedulingBlocks };
