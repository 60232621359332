
import { Col, Form, Row } from "antd"
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { useDictionaryTranslation } from "../../../hooks/useDictionaryTranslation";
import { Button } from "../../../components/buttons/buttons";
import { FormWrapper } from "../../../components/formWrapper/form-wrapper";
import { StyledCheckbox, ModalColumns } from "../Style";
import { pendingsBoardsColumns } from "../constants/pendingsBoardsConstants";
import { usePendingsBoard } from "../hooks/usePendingsBoard";

export const ColumnSettingsModal = ({ show, handleClose }) => {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();
  const { settingData, updateBoardSettings } = usePendingsBoard();

  const closeModal = () => {
    form.resetFields();
    handleClose();
  };

  const handleSubmit = async() => {
    const formValues = form.getFieldsValue();
    const data = {
      fields: formValues
    }
    await updateBoardSettings(data)
    handleClose(true)
  }

  useEffect(() => {
    if (settingData == null) return;
    const formValues = {};
    Object.keys(pendingsBoardsColumns).forEach((field) => {
      formValues[field] = settingData.fields[field]
    })
    form.setFieldsValue(formValues);
  },[form, settingData])

  return (
    <ModalColumns
      title={t('boardFields')}
      destroyOnClose
      width="25%"
      height="50%"
      onCancel={closeModal}
      visible={show}
      footer={null}
    >
      <FormWrapper style={{ padding: '0px', margin: '0px' }}>
        <Form name='boardFieldsForm' layout='vertical' form={form} onFinish={handleSubmit}>
          <Row>
            <Col xs={24} md={24}>
              {Object.entries(pendingsBoardsColumns).map(([key, value]) => (
                <Form.Item
                  valuePropName="checked"
                  name={key}
                  key={key}
                  style={{ padding: '0px', marginBottom: '0px', height: '35px' }}
                >
                  <StyledCheckbox>
                    {t(value.name)}
                  </StyledCheckbox>
                </Form.Item>
              ))}
            </Col>
          </Row>
          <Row justify="end">
            <Button form="boardFieldsForm" key="submit" htmlType="submit" type="primary">
              {t('save')}
            </Button>
          </Row>
        </Form>
      </FormWrapper>
    </ModalColumns>
  )
}

ColumnSettingsModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};