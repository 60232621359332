import Styled from 'styled-components';

const DescriptionText = Styled.p`
  color: #aaa; 
  padding: 0px 30px; 
  fontWeight: 500;
`;

const ScheduleBlockTextContainer = Styled.div`
  margin-left: 5px;
  padding-top: 10px;
`;

const ScheduleBlockContainer = Styled.div`
  margin-bottom: 10px;
`;

const DayItemContainer = Styled.div`
  margin-top: 15px;
`;

export { DescriptionText, ScheduleBlockTextContainer, ScheduleBlockContainer, DayItemContainer };
