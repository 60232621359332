

const ProfileTypes = Object.freeze({
  MASTER: {name: 'master', id: '-1'},
  ADMIN: {name:'admin', id: '1' },
  OPERATOR: {name:'operator', id: '2' },
  TECHNICIAN: {name:'technician', id: '4' },
  INTERESTED: { name:'interested' ,id: '5' },
});

export { ProfileTypes };
