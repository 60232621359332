import styled from 'styled-components';
import { Checkbox, Modal, Tag } from 'antd';
import { Button } from '../../components/buttons/buttons';


const ButtonClock = styled(Button)`
  width: 35px;

  .clockIcon {
    color: white;
    width: 20px;
    height: 20px;
  }
  .listIcon {
    color: grey;
    width: 20px;
    height: 20px;
  }
  .filterIcon {
    color: grey;
    width: 15px;
    height: 15px;
  }
`

const ReturnButton = styled(Button)`
  width: 50px;
  height: 100%;
  margin: 10px 3px 7px 0.5em;
  .arrowIcon {
    color: grey;
    width: 30px;
    height: 30px;
  }
`

const LogoImg = styled.img`
  width: 150px;
  height: 30px;
  margin: 5px 0px;
`


const HeaderSpace = styled.div`
  width: 97%;
  display: flex;
  justify-content: space-between;
  background: #F4F5F7;
`

const TagTable = styled(Tag)`
  height: 30px;
  width: 100%;
  font-size: 13px !important;
  padding: 5% 0% !important;
  text-align: center;
  border-top-left-radius: 1em 1em !important;
  border-top-right-radius: 1em 1em !important;
  border-bottom-right-radius: 1em 1em !important;
  border-bottom-left-radius: 1em 1em !important;
`
const ModalColumns = styled(Modal)`
  .ant-modal-content {
    .ant-modal-header{
      padding-left: 16px;
    }
    .ant-modal-body{
      padding: 13px 13px 20px 13px;
    }
  }

`

const CheckboxModal = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  margin-left: ${props => (props.first ? '0' : '8px')};
`;


export { ButtonClock, TagTable, HeaderSpace, ReturnButton, LogoImg, CheckboxModal, StyledCheckbox, ModalColumns }