/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: '/auth/login',
    currentUser: '/auth/user',
  },
  services: {
    root: '/services/',
    datesAvailability: 'dates-availability',
    resultsVerification: 'results-verification',
    servicesHistorySettings:'/services-history-settings'
  },
  serviceTypes: {
    root: '/service-types/',
    notifications: 'notifications',
    selfScheduling: 'self-scheduling',
    variables: 'variables',
    pdfTemplatesSettings: 'pdf-templates-settings',
  },
  companies: {
    root: '/companies/',
    contacts: 'contacts',
    variables:'variables',
    serviceTypeAssignations: 'service-type-assignations',
  },
  incidentForms: {
    root: '/incidents-forms/',
  },
  dictionaries: {
    root: '/dictionaries/',
    commonTerms: 'common-terms',
    profileNames: 'profile-names',
  },
  serviceStatus: {
    root: '/service-status/',
  },
  users: {
    root: '/users/',
    profileCounts: 'profile-counts',
    advancedAvailability: 'advanced-availability',
    password: 'password',
    changeStatus: 'change-status',
  },
  finalCustomers: {
    root: '/final-customers/',
  },
  interoperableData: {
    root: '/interoperable-data/',
    bloodTypes: 'blood-types',
    documentTypes: 'document-types',
    biologicalSexes: 'biological-sexes',
    healthInsurances: 'health-insurances',
    countries: 'countries',
    cities: 'cities',
    departments: 'departments',
    regimes: 'regimes',
  },
  importData: {
    finalCustomers: 'final_customers/import',
    importCustomList: 'custom-lists/',
  },
  accounts: {
    root: '/accounts',
    signedPost: 'menu-logo-signed-post',
    mobileSignedPost: 'mobile-logo-signed-post',
    validations: 'validations',
    checkPrefix: 'prefix-availability',
    publicRegistration: 'public-registration',
    profile: 'account-profile',
  },
  admin: {
    root: '/admin',
    account: 'account',
  },
  selfScheduling: {
    root: '/self-scheduling',
    mainPage: 'main-page',
    banner: 'banner',
    logo: 'logo',
    assignableUsers: 'assignable-users',
    finalCustomerFields: 'final-customer-fields',
  },
  appointmentSelfScheduling: {
    root: '/appointment-self-scheduling',
    token: 'auth/login',
    landingPageData: 'landing-data',
    appointmentTypes: 'appointment-types',
    scheduleBlocks: 'schedule-blocks',
    headquarters: 'headquarters',
    finalCustomers: 'finalCustomers',
    patient: 'patient',
    cities: 'cities',
    departments: 'departments',
    countries: 'countries',
    regimes: 'health-insurance-regimes',
    healthInsurances: 'health-insurances',
    professionals: 'professionals',
    legalData: 'legal-data',
    terms: 'legal-terms',
    dataTreatment: 'data-treatment-policy',
    dictionary: 'dictionary',
    extendedForms: 'extended-forms',
    assets: 'assets',
    unavailableDates: 'unavailable-dates',
    bloodTypes: 'blood-types',
    patienSelfScheduleFields: 'patient/self-schedule-fields',
    customers: 'customers',
    customLists: {
      root: 'custom-lists',
      listRoot: 'root',
      listItems: 'list-items',
    },
    fileUploads: {
      root: 'file-uploads',
      signedPost: 'signed-post',
    },
    reCaptcha: {
      root: 'recaptcha',
      validateV2: 'validation/v2',
    },
  },
  customLists: {
    root: '/custom-lists/',
    listItems: 'list-items',
    listRoot: 'root',
  },
  assets: {
    root: '/assets/',
    scheduleTimes: 'schedule/',
  },
  serviceAssetTypes: {
    root: '/asset-types',
  },
  fileUploads: {
    root: '/file-uploads',
    signedPost: 'signed-post',
  },
  utils: {
    root: '/utils',
    colors: 'colors',
    languages: 'languages',
    timezones: 'timezones',
  },
  specialties: {
    root: '/specialties',
  },
  applications: {
    root: '/applications',
  },
  incidentsForms: {
    root: '/incidents-forms',
  },
  headquarters: {
    root: '/headquarters',
    defaultHeadquarter: 'default-headquarter',
    lockedDates: 'locked-dates',
  },
  scheduleTimes: {
    root: '/schedule-times',
  },
  availabilityLocks: {
    root: '/availability-locks',
    history: 'availability-locks/history',
    verify: '/availability-locks/schedule-verification',
  },
  servicesStatus: {
    root: '/service-status',
  },
  gadgetsHtml: {
    root: '/gadgets-html',
  },
  credentiaslApi: {
    root: '/credentials-api',
    apiToken: 'api-token',
  },
  availability: {
    root: '/availability',
  },
  serviceNoteTypes: {
    root: '/service-notes-types',
  },
  serviceCancellationReassons: {
    root: '/service-cancellation-reassons',
  },
  serviceFinalCustomersTags: {
    root: '/final-customer-tags',
  },
  modules: {
    root: '/modules',
  },
  awaitingList: {
    root: '/patients-awaiting-list',
  },
  whatsapp: {
    root: '/messaging',
    whatsapp: 'whatsapp',
    broadcastMessages: 'broadcast-messages',
  },
  whatsaapAcountConfig: {
    root: '/whatsapp-config',
    verificationToken: 'verification-token',
  },
  patientOverview: {
    root: '/patient-overview/',
  },
  legalTerms: {
    root: '/legal-data',
    terms: 'legal-terms',
    dataTreatment: 'data-treatment-policy',
  },

  pdfSettings: {
    root: '/pdf-settings',
    templates: '/pdf-templates',
  },
  pdfTemplatesSettings: {
    root: '/pdf-templates-settings',
  },

  accountProfile: {
    root: '/account-profile',
  },
  notes: {
    root: '/service-notes',
    attachments: 'attachments'
  },
  surveysGroups: {
    root: '/survey-groups',
  },
  surveys: {
    root: '/surveys',
  },
  surveyTemplates: {
    root: '/survey-templates',
  },
  surveysSent: {
    root: '/surveys-sent',
  },
  incidentsMap: {
    root: '/incidents-map',
    users: '/users',
  },
  supportChats: {
    root: '/support-chats',
    attachmentSignedPost: 'attachment-signed-post',
    chatMessages: 'chat-messages',
    updates: 'updates',
  },
  pendingsBoard: {
    root: '/services-board',
    settings: '/services-board/settings',
  },
  customViewsReports: {
    root: '/custom-views-reports',
  },
  quickAnswers: {
    root: '/quick-answers',
  },
  sequences: {
    root: '/sequences',
  },
  customReportsSettings: {
    root: '/custom-reports-settings/',
    count: 'count-by-user',
    byUser: 'by-user',
  },
};

export { API };
