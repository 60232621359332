import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { askForAuthCheck, logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { useAccountActions } from '../../../views/accounts/hooks/useAccountActions';

const AuthInfo = () => {
  const { translate: t } = useDictionaryTranslation();
  const { manageAccount } = useAccountActions();
  const { currentUser, manageAccounts } = useSelector((state) => ({
    currentUser: state.auth.currentUser,
    manageAccounts: state.auth.permissions.manageAccounts,
  }));
  const dispatch = useDispatch();

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const signOutAccount = async (e) => {
    e.preventDefault();
    await manageAccount(null);
    dispatch(askForAuthCheck());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {/* <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
          <figcaption>
            <Heading as="h5">{currentUser?.profile?.name}</Heading>
            <p>{currentUser.username}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        {manageAccounts ? (
          <Link className="user-dropdwon__bottomAction" onClick={signOutAccount} to="#">
            <FeatherIcon icon="list" />
            {t('viewAccounts')}
          </Link>
        ) : null}
        <Link className="user-dropdwon__bottomAction" onClick={signOut} to="#">
          <FeatherIcon icon="log-out" />
          {t('signOut')}
        </Link>
      </div>
    </UserDropDwon>
  );
  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar size={40} icon={<UserOutlined style={{ display: 'inline' }} />} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
