import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useDictionaryTranslation = () => {
  const dictionary = useSelector((state) => state.dictionary.data);
  const { t } = useTranslation();

  const translate = useCallback(
    (key, data = {}) => {
      return t(key, { ...data, dictionary });
    },
    [t, dictionary],
  );

  return {
    translate,
  };
};
