import React from 'react';
import { PageHeader } from 'antd';
import { CustomListTable } from './components/customLists/CustomListTable';
import { Main } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';

const CustomList = () => {
  const { translate: t } = useDictionaryTranslation();

  return (
    <>
      <PageHeader title={t('customLists')} ghost />

      <Main >
        <Cards headless>
          <CustomListTable />
        </Cards>
      </Main>
    </>
  );
};

export default CustomList;
