import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomHtml } from '../../redux/customHtml/actionCreator';
import { useTrustedExternalHtml } from '../widgetsExternal/hooks/useTrustedExternalHtml';

export function WidgetsHtml() {
  const dispatch = useDispatch();
  const customHtml = useSelector((state) => state.customHtml.customHtml);
  const { widgetRef } = useTrustedExternalHtml(customHtml);

  useEffect(() => {
    dispatch(addCustomHtml());
  }, []);

  return <div id="pata" ref={widgetRef} />;
}
