import actions from './actions';

const { ACCOUNT_VALIDATION_BEGIN, ACCOUNT_VALIDATION_SUCCESS, ACCOUNT_VALIDATION_ERR } = actions;

const accountValidationBegin = state => {
  return {
    ...state,
    loading: true,
  };
};

const accountValidationSuccess = (state, { data }) => {
  const { token, accountData, professionalData } = data;
  return {
    ...state,
    token,
    accountData,
    professionalData,
    loading: false,
    checkAccount: false,
    accountValidated: true,
    error: null,
  };
};

const accountValidationError = (state, { err }) => {
  return {
    ...state,
    error: err,
    loading: false,
    checkAccount: false,
  };
};

const actionHandlers = {
  [ACCOUNT_VALIDATION_BEGIN]: accountValidationBegin,
  [ACCOUNT_VALIDATION_SUCCESS]: accountValidationSuccess,
  [ACCOUNT_VALIDATION_ERR]: accountValidationError,
};

export default actionHandlers;
