import actions from './actions';
import { getPermissions } from './utils/authUtils';
import { AuthService } from '../../services/authService';
import { removeItem, setItem } from '../../utility/localStorageControl';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, authRecheck, assignModules } = actions;

const login = (username, password) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      const { user, token, accountData, accountModules } = await AuthService.login(username, password);
      setItem('access_token', token);
      const permissions = getPermissions(user?.profile, accountData);
      return dispatch(loginSuccess({ currentUser: user, accountData, permissions, accountModules }));
    } catch (err) {
      return dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      removeItem('access_token');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const checkAuth = () => {
  return async (dispatch) => {
    try {
      const { user, accountData, accountModules } = await AuthService.getCurrentUser();
      const permissions = getPermissions(user?.profile, accountData);
      return dispatch(loginSuccess({ currentUser: user, accountData, permissions, accountModules }));
    } catch (error) {
      removeItem('access_token');
      return dispatch(loginErr(error));
    }
  };
};

const askForAuthCheck = () => {
  return async (dispatch) => {
    return dispatch(authRecheck())
  }
}

const assignAccountModules = (accountModules) => {
  return async (dispatch) => {
    return dispatch(assignModules({accountModules}))
  }
}

export { login, logOut, checkAuth, askForAuthCheck, assignAccountModules };
