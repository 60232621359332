import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useColorList = () => {
  const colorList = useSelector((state) => {
    return state.colorList.data;
  });

  const getColorById = useCallback((colorId, defaultColor = colorList?.[0]?.color) => {
    const selectedColor = colorList.find((color) => colorId === color.id)?.color;
    if (selectedColor == null && defaultColor != null) {
      return defaultColor;
    }
    return selectedColor;
  }, [colorList]);

  const getIdByColor = useCallback((hexColor, defaultColor = colorList?.[0]?.id) => {
    const selectedId= colorList.find((color) => hexColor === color.color)?.id;
    if (selectedId == null && defaultColor != null) {
      return defaultColor;
    }
    return selectedId;
  }, [colorList]);

  return { colorList, getColorById, getIdByColor };
};
