import actions from './actions';

const { COLOR_LIST_BEGIN, COLOR_LIST_SUCCESS, COLOR_LIST_ERR } = actions;

const initState = {
  loading: false,
  data: [],
};

const ColorListReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case COLOR_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case COLOR_LIST_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case COLOR_LIST_ERR:
      return {
        ...state,
        err,
        loading: false,
      };
    default:
      return state;
  }
};

export default ColorListReducer;
