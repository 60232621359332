import actions from './actions';
import { UtilsService } from '../../services/utilsService';

const { colorListBegin, colorListSuccess, colorListErr } = actions;

const getColorList = () => {
  return async dispatch => {
    try {
      dispatch(colorListBegin());
      const { colorList } = await UtilsService.getColors();
      dispatch(colorListSuccess(colorList));
    } catch (error) {
      dispatch(colorListErr(error));
    }
  };
};

export { getColorList };
