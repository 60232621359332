import React from 'react';
import PropTypes from 'prop-types';

export const BulletIcon = ({ size = 13, color = 'black' }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        margin: '0 5px',
        background: color,
        height: size,
        width: size,
        borderRadius: '50%',
      }}
    />
  );
};

BulletIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
