import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class CustomReportsSettingsService {
  static async getAll({ page, search}) {
    const queryParams = {
      search,
      page,
    };
    const { data } = await DataService.get(API.customReportsSettings.root, queryParams);
    return {
      customReportsSettings: data.customReportsSettings,
      total: data.total,
      pageSize: data.pageSize,
      page: data.page,
    };
  }

  static async getById(customReportId) {
    const { data } = await DataService.get(API.customReportsSettings.root + customReportId);
    return data
  }

  static async getByUser() {
    const { data } = await DataService.get(API.customReportsSettings.root + API.customReportsSettings.byUser);
    return data
  }

  static async createCustomReportsSettings(customReportData) {
    const body = {
      ...customReportData
    };
    const { data } = await DataService.post(API.customReportsSettings.root, body);
    return data.customReportsSettings;
  }

  static async updateCustomReportsSettings(customReportId, customReportData) {
    const body = {
      ...customReportData
    };
    const { data } = await DataService.patch(API.customReportsSettings.root + customReportId, body);
    return data.customReportsSettings;
  }

  static async removeCustomReportsSettings(customReportId) {
    const { data } = await DataService.delete(API.customReportsSettings.root + customReportId);
    return data.user;
  }

  static async getCountCustomReports() {
    const { data } = await DataService.get(API.customReportsSettings.root + API.customReportsSettings.count);
    return data
  }
}

export { CustomReportsSettingsService };
