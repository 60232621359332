import { useState, useCallback } from 'react';
import { useTableData } from '../../../../../components/dataTable/hooks/useTableData';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { CustomListsService } from '../../../../../services/customListService';

const useCustomListTable = () => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [isLoading,setIsLoading] = useState(false);
  const { setTableData, ...tableData } = useTableData({
    data: [],
    page: 1,
    total: 0,
    pageSize: 0,
    search: '',
  });

  const getTableData = useCallback(
    async ({ page, search = '' }) => {
      setIsLoading(true);
      try {
        const { customLists: data, total, pageSize } = await CustomListsService.getAllList({ page, search });
        setTableData({
          data,
          total,
          pageSize,
          page,
          search,
        });
      } catch (error) {
        showErrorNotification({
          title: 'error',
          description: 'errorRetrievingRecords',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [setTableData, showErrorNotification],
  );

  const reloadData = () => getTableData({ page: tableData.page, search: tableData.search });

  const deleteList = async (customListId) => {
    try {
      await CustomListsService.removeList({ listId: customListId });
      reloadData();
      showSuccessNotification({
        title: 'success',
        description: 'successRemovingRecord',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRemovingRecord',
      });
    }
  };

  return {
    deleteList,
    tableData,
    reloadData,
    getTableData,
    isLoading
  };
};

export default useCustomListTable;
