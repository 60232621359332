import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

export class FileUploadsService {
  static uploadPaths = { CUSTOM_FORMS: 'customForms', ACCOUNTS: 'companies_vb', SUPPORT_CHAT: 'support_chat' };

  static async getSignedPost({ path, filename, contentType }) {
    const params = { path, filename, contentType };
    const { data } = await DataService.get(`${API.fileUploads.root}/${API.fileUploads.signedPost}`, params);
    return {
      signedPost: data.signedPost,
      fileKey: data.fileKey,
    };
  }

  static async uploadSignedFile(signedPost, file) {
    const formData = new FormData();
    Object.entries(signedPost.fields).forEach(([key, value]) => formData.append(key, value));
    formData.append('file', file);
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    const response = await fetch(signedPost.url, requestOptions);
    return response.text();
  }

  static async uploadFile(path, file) {
    const { signedPost, fileKey } = await FileUploadsService.getSignedPost({
      path,
      filename: file.name,
      contentType: file.type,
    });
    const result = await FileUploadsService.uploadSignedFile(signedPost, file);
    return {
      resultUploadFile: result,
      fileKey,
    };
  }
}
