import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from '../../dropdown-select/DropdownSelect';
import { IncidentTypesService } from '../../../services/incidentTypesService';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';

export const SelectServiceType = ({ selectedServiceType, onChange, multiple = false, idHeadquarter, idCustomer, autoSelect }) => {
  const { translate: t } = useDictionaryTranslation();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getItemData = (item) => {
    if (item.data) return item;
    return {
      data: serviceTypes.find((s) => s._id === item.value),
      value: item.value,
      label: item.label,
    };
  };

  const selectServiceType = (item) => {
    if (multiple) {
      onChange(item.map(getItemData));
      return;
    }
    onChange(getItemData(item)?.data);
  };

  const getServiceTypes = useCallback(
    async (search) => {
      setLoading(true);
      try {
        const { incidentTypes } = await IncidentTypesService.getAll({ search, idHeadquarter, idCustomer });
        setServiceTypes(incidentTypes);
        const canSetSingleValue = multiple ? !selectedServiceType?.length : !selectedServiceType;
        if (autoSelect && canSetSingleValue && incidentTypes?.length === 1) {
          const selectedItem = {
            data: incidentTypes[0],
            value: incidentTypes[0]._id,
            label: incidentTypes[0].name,
          };
          if (multiple) {
            selectServiceType([selectedItem]);
          } else {
            selectServiceType(selectedItem);
          }
        }
      } catch (error) {
        console.error(error);
        setServiceTypes([]);
      } finally {
        setLoading(false);
      }
    },
    [idHeadquarter, idCustomer, selectedServiceType],
  );

  const getOptionData = useCallback((serviceType) => {
    return {
      data: serviceType,
      value: serviceType._id,
      label: serviceType.name,
    };
  }, []);

  useEffect(() => {
    getServiceTypes();
  }, [getServiceTypes]);

  return (
    <DropdownSelect
      placeholderText={t('serviceTypePlaceholder')}
      isLoading={loading}
      onChange={selectServiceType}
      options={serviceTypes}
      value={selectedServiceType}
      onSearch={getServiceTypes}
      getOptionData={getOptionData}
      multiple={multiple}
    />
  );
};

SelectServiceType.propTypes = {
  selectedServiceType: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  idHeadquarter: PropTypes.string,
  autoSelect: PropTypes.bool,
  idCustomer: PropTypes.string,
};
