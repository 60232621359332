import { API } from "../config/api";
import { DataService } from "../config/dataService/dataService";

export class AdminService {
  static async manageAccount(accountId) {
    const params = { accountId };
    const { data } = await DataService.post(`${API.admin.root}/${API.admin.account}`, params);
    return {
      success: data.success,
    };
  }
}