import { notification, Modal } from 'antd';
import { useCallback } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDictionaryTranslation } from './useDictionaryTranslation';

export const useNotifications = () => {
  const { translate: t } = useDictionaryTranslation();

  const getNotificationData = useCallback(
    (title, description) => {
      return {
        message: t(title),
        description: t(description),
      };
    },
    [t],
  );

  const showSuccessNotification = useCallback(
    ({ title, description }) => {
      notification.success(getNotificationData(title, description));
    },
    [getNotificationData],
  );

  const showErrorNotification = useCallback(
    ({ title, description }) => {
      notification.error(getNotificationData(title, description));
    },
    [getNotificationData],
  );

  const showConfirmNotification = useCallback(
    ({ title, content, onOk, onCancel, cancelText = 'cancel', okText = 'ok' }) => {
      Modal.confirm({
        title: t(title),
        content: t(content),
        onOk,
        onCancel,
        cancelText: t(cancelText),
        okText: t(okText),
      });
    },
    [t],
  );

  const showAllowCreationNotification = useCallback(
    ({ title, content, onOk, onCancel, cancelText = 'cancel', okText = 'ok' }) => {
      Modal.confirm({
        title: t(title),
        content: t(content),
        onOk,
        onCancel,
        cancelText: t(cancelText),
        okText: t(okText),
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
      });
    },
    [t],
  );

  return {
    showSuccessNotification,
    showErrorNotification,
    showConfirmNotification,
    showAllowCreationNotification,
  };
};
