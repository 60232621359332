import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Divider } from 'antd';
import { DayItem } from './dayItem';
import { useDictionaryTranslation } from '../../../../../hooks/useDictionaryTranslation';

export const EditSchedulingBlocks = ({ days, onChange }) => {
  const { translate: t } = useDictionaryTranslation();

  const onDayChange = (dayId) => (data) => {
    const newData = days.map((day) => {
      if (day.dayId !== dayId) return day;
      return {
        ...day,
        ...data,
      };
    });
    onChange(newData);
  };

  return (
    <div>
      <b>{t('selectAppointmentsTimes')}</b>
      <Row>
        <Col xs={24} md={16}>
          {days?.map((day, dayIndex) => {
            return (
              <React.Fragment key={day.dayId}>
                <DayItem
                  checked={day.checked}
                  blocks={day.blocks}
                  label={day.label}
                  onChange={onDayChange(day.dayId)}
                />
                {dayIndex < days.length - 1 ? <Divider /> : null}
              </React.Fragment>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

EditSchedulingBlocks.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      blocks: PropTypes.array,
      label: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
};
