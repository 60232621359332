import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditSchedulingBlocks } from '../../selfScheduling/components/form/editScheduleBlocks/editScheduleBlocks';

const SchedulingTimesField = ({ value, onChange }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const onChangeBlocks = (newValue) => {
    setCurrentValue(newValue);
    if (onChange != null) onChange(newValue);
  };

  useEffect(() => {
    setCurrentValue(value)
  },[value])

  return (
    <>
      <EditSchedulingBlocks days={currentValue} onChange={onChangeBlocks} />
    </>
  );
};

export default SchedulingTimesField;
SchedulingTimesField.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};
