import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import constants from '../../config/api/constants';

export const MenuLogo = () => {
  const { logo, staticPrefix } = useSelector((state) => {
    return {
      logo: state.auth.accountData?.micitaMenuLogo,
      staticPrefix: state.auth.accountData?.staticPrefix,
    };
  });
  const logoUrl = useMemo(() => {
    return !logo ? null : `${constants.s3Path}/${staticPrefix}/${logo}`;
  });
  return <img src={logoUrl ?? require('../../static/img/Logo_micita.png')} alt="" />;
};
