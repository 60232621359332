import { useCallback, useEffect, useState } from 'react';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { CustomListsService } from '../../../../../services/customListService';

export const useListNodesContextValue = (listId) => {
  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorNotification } = useNotifications();

  const getListNodes = useCallback(async () => {
    if (!listId) {
      setNodes([]);
      return;
    }
    setIsLoading(true);
    try {
      const listNodes = await CustomListsService.getAllListNodes(listId);
      setNodes(listNodes.listNodes);
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRetrievingRecords',
      });
    } finally {
      setIsLoading(false);
    }
  }, [listId, showErrorNotification]);

  const updateListNodes = useCallback(
    async (newNodes) => {
      setIsLoading(true);
      try {
        const data = await CustomListsService.setListNodes(listId, newNodes);
        setNodes(data.listNodes);
      } catch (error) {
        showErrorNotification({
          title: 'error',
          description: 'errorUpdatingRecord',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [listId, showErrorNotification],
  );

  useEffect(() => {
    getListNodes();
  }, [getListNodes]);

  return {
    nodes,
    setListNodes: updateListNodes,
    getListNodes,
    isLoading,
  };
};
