import accountValdationActionHandlers from './actions/accountValidation/handlers';
import landingDataActionHandlers from './actions/landingData/handlers';

const actionHandlers = {
  ...accountValdationActionHandlers,
  ...landingDataActionHandlers,
};

const initialState = {
  checkAccount: true,
  loading: false,
  accountValidated: false,
  accountData: null,
  landingPageData: null,
};

const SelfSchedulingReducer = (state = initialState, action) => {
  const { type } = action;
  const actionHandler = actionHandlers[type];
  return actionHandler?.(state, action) ?? state;
};

export default SelfSchedulingReducer;
