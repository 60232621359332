import { useCallback, useState } from "react";
import { useNotifications } from "../../../hooks/useNotifications";
import { GadgetsHtmlService } from "../../../services/gadgetsHtmlService";

export function useExternalWidgets() {
  const [widgets, setWidgets] = useState({ html: '' });
  const [loading, setLoading] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const getWidgets = useCallback(async () => {
    setLoading(true);
    try {
      const { gadgets } = await GadgetsHtmlService.getGadgetsHtml();
      setWidgets(gadgets);
    } catch(error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRetrievingRecord',
      });
    } finally {
      setLoading(false);
    }
  }, [showErrorNotification])

  const saveWidgets = useCallback(async (html) => {
    setLoading(true);
    try {
      const { gadgets } = await GadgetsHtmlService.updateGadgetsHtml(html);
      setWidgets(gadgets);
      showSuccessNotification({
        title: 'success',
        description: 'successUpdatingRecord',
      });
    } catch(error) {
      showErrorNotification({
        title: 'error',
        description: 'errorSavingRecord',
      });
    } finally {
      setLoading(false);
    }
  }, [showErrorNotification, showSuccessNotification])

  useState(() => {
    getWidgets();
  }, [getWidgets]);

  return {
    loading,
    widgets,
    saveWidgets,
  }
}