import { Button, Col, PageHeader, Row, Spin } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { ButtonAddKey, ApiMessage, TokenText, Tokencontainer } from './styled';
import { Main } from '../../container/styled';
import { Cards } from '../cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import useApiKey from '../../views/api/hooks/useApiKey';
import { useNotifications } from '../../hooks/useNotifications';
import { theme } from '../../config/theme/themeVariables';

const ApiCreate = () => {
  const { translate: t } = useDictionaryTranslation();
  const { dataToken, loading, generateKey } = useApiKey();
  const { showSuccessNotification } = useNotifications();

  const handleClick = () => {
    generateKey();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(dataToken?.token);
    showSuccessNotification({
      title: 'success',
      description: 'textCopyToClipBoard',
    });
  };

  return (
    <>
      <PageHeader title={t('api')} ghost />
      <Main>
        <Cards headless>
          <Spin spinning={loading}>
            <div>
              <Row justify="end">
                <ButtonAddKey onClick={() => handleClick()}>{t('apiCreate')}</ButtonAddKey>
              </Row>
              {dataToken?.token != null ? (
                <>
                  <ApiMessage>
                    {t('apiMessage')}
                    <span style={{ marginLeft: '10px', color: theme['primary-color'] }}>{dataToken.date}</span>
                  </ApiMessage>
                  <Row>
                    <Col md={24} sm={24}>
                      <Tokencontainer>
                        <Row>
                          <Col xs={23}>
                            <TokenText>{dataToken.token.slice(0, 100)}</TokenText>
                          </Col>
                          <Col xs={1}>
                            <Button
                              onClick={handleCopy}
                              type="text"
                              style={{ color: '#aeb4c1' }}
                              icon={<CopyOutlined />}
                            />
                          </Col>
                        </Row>
                      </Tokencontainer>
                    </Col>
                  </Row>
                </>
              ) : (
                <ApiMessage>{t('noApiKeysGenerated')}</ApiMessage>
              )}
            </div>
          </Spin>
        </Cards>
      </Main>
    </>
  );
};

export default ApiCreate;
