import { useCallback, useEffect, useState } from 'react';
import { SupportChatsService } from '../../../../services/supportChatsService';

export function useChatUpdates() {
  const [chatUpdates, setChatUpdates] = useState({
    hasAssignedChats: false,
    hasUnassignedChats: false,
  });

  const getUpdates = useCallback(async () => {
    const { updates } = await SupportChatsService.getUpdates();
    setChatUpdates(updates);
  });

  useEffect(() => {
    getUpdates();
    const interval = setInterval(getUpdates, 30000); // 30 seconds
    return () => {
      clearInterval(interval);
    };
  }, []);

  return chatUpdates;
}
