import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

const accounts = [{ name: 'jmaster' }, { name: 'cmaster' }, { name: 'emaster' }];
class AccountsService {
  static getAccounts() {
    const data = accounts;
    return {
      data,
    };
  }

  static async getDataAccounts({ page, search }) {
    const params = {
      page,
      search,
    };
    const { data } = await DataService.get(API.accounts.root, params);
    return {
      accounts: data.accounts,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async getAllTimeZone() {
    const { data } = await DataService.get(API.accounts.root);
    return {
      timeZone: data.timeZone,
    };
  }

  static async getAccountById(accountId) {
    const { data } = await DataService.get(`${API.accounts.root}/${accountId}`);
    return {
      account: data.account,
    };
  }

  static async createAccount(newData) {
    const accountData = {
      name: newData.name,
      prefix: newData.prefix,
      email: newData.email,
      country: newData.country,
      colorApplication: newData.colorApplication,
      language: newData.language,
      timezoneId: newData.timezoneId,
      address: newData.address,
      markerCoords: newData.markerCoords,
      adminData: {
        email: newData.adminData.email,
        password: newData.adminData.password,
      },
    };
    const { data } = await DataService.post(API.accounts.root, accountData);
    return {
      account: data.account,
    };
  }

  static async updateAccount(id, newData) {
    const accountData = {
      name: newData.name,
      prefix: newData.prefix,
      email: newData.email,
      country: newData.country,
      colorApplication: newData.colorApplication,
      language: newData.language,
      timezoneId: newData.timezoneId,
      enableAccountCustomization: newData.enableAccountCustomization,
      enableAccountAPI: newData.enableAccountAPI,
      enableFieldWork: newData.enableFieldWork,
      enableIncidentsOffschedule: newData.enableIncidentsOffschedule,
      enableForcedAvailabilityLocks: newData.enableForcedAvailabilityLocks,
      address: newData.address,
      markerCoords: newData.markerCoords,
    };
    const { data } = await DataService.patch(`${API.accounts.root}/${id}`, accountData);
    return {
      account: data.account,
    };
  }

  static async removeAccounts(id) {
    const { data } = await DataService.delete(`${API.accounts.root}/${id}`);
    return {
      account: data.account,
    };
  }

  static async getSignedPost({ contentType }) {
    const params = { contentType };
    const { data } = await DataService.get(`${API.accounts.root}/${API.accounts.signedPost}`, params);
    return {
      signedPost: data.signedPost,
      fileKey: data.fileKey,
    };
  }

  static async checkPrefixAvailability(prefix, accountId) {
    const params = {
      prefix,
      accountId,
    };
    const { data } = await DataService.get(
      `${API.accounts.root}/${API.accounts.validations}/${API.accounts.checkPrefix}`,
      params,
    );
    return data.isAvailable;
  }
}

export { AccountsService };
