import React from 'react'
import ApiCreate from '../../components/Api/ApiCreate'

const Api = () => {
  return (
    <div>
      <ApiCreate/>
    </div>
  )
}

export default Api
