import React, { useEffect } from 'react';
import { Form, Modal, Row, Col, Input, Space } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from '../../../components/buttons/buttons';
import { FormWrapper } from '../../../components/formWrapper/form-wrapper';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { ScheduleBlockItem } from '../../selfScheduling/components/form/editScheduleBlocks/scheduleBlockItem';

function ScheduleLockItem({ startDate, endDate, onChange, addBlock, removeBlock }) {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();

  const onChangeDates = (value) => {
    const blockDay = form.getFieldValue('blockDay');
    const valueKeys = [
      { key: 'from', value: 'startDate' },
      { key: 'to', value: 'endDate' },
    ];
    const result = {};
    valueKeys.forEach((item) => {
      const itemValue = value[item.key];
      if (itemValue == null) {
        return;
      }
      const { 0: hour, 1: minute } = itemValue.split(':');
      result[item.value] = moment(blockDay).set({ hour, minute }).toISOString();
    });
    onChange({
      startDate,
      endDate,
      ...result,
    });
  };

  const onChangeBlockDate = (value) => {
    const date = moment(value);
    const dateParams = { date: date.date(), month: date.month(), year: date.year() };
    onChange({
      startDate: moment(startDate).set(dateParams).toISOString(),
      endDate: moment(endDate).set(dateParams).toISOString(),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      blockDay: moment(startDate).format('YYYY-MM-DD'),
    });
  }, [form, startDate, endDate]);

  return (
    <FormWrapper>
      <Form form={form}>
        <Row>
          <Col md={9}>
            <Form.Item name="blockDay" rules={[{ required: false, message: t('birthdateRequired') }]}>
              <Input placeholder={t('serviceDatePlaceholder')} type="date" onChange={onChangeBlockDate} />
            </Form.Item>
          </Col>
          <Col md={13}>
            <Space size="middle" style={{ marginTop: '0.6em', marginRight:'0px'}}>
              <ScheduleBlockItem
                from={moment(startDate).format('HH:mm')}
                to={moment(endDate).format('HH:mm')}
                onChange={onChangeDates}
                onRemove={removeBlock}
              />
            </Space>
          </Col>
          <Col md={2}>
            <Space size="small" style={{ marginTop: '1.3em'}}>
              <Button
                style={{ backgroundColor: 'white', color: 'black'}}
                type="text"
                onClick={addBlock}
                icon={<PlusOutlined />}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  );
}

export const ModalAddBlokedDays = ({ blocks, show, handleClose }) => {
  const [blockList, setBlockList] = React.useState(blocks ?? []);
  const { translate: t } = useDictionaryTranslation();

  const closeModal = (value) => {
    handleClose(value);
  };

  const onSubmit = () => {
    const resultBlockList = blockList.filter((item) =>
      [item.from, item.to].every((date) => !Number.isNaN(new Date(date).getTime())),
    );
    closeModal(resultBlockList);
  };

  const addBlock = () => {
    setBlockList([...blockList, { from: '', to: '' }]);
  };

  const removeBlock = (index) => () => {
    const newList = blockList.filter((_, itemIndex) => itemIndex !== index);
    setBlockList(newList);
  };

  const onChangeItem = (index) => (value) => {
    const newList = blockList.map((item, i) => {
      if (i === index) {
        return {
          from: value.startDate ?? item.from,
          to: value.endDate ?? item.to,
        };
      }
      return item;
    });
    setBlockList(newList);
  };

  return (
    <Modal
      title={t('addBlockedDays')}
      destroyOnClose
      width="45%"
      height="50%"
      onCancel={closeModal}
      show={show}
      visible={show}
      footer={
        <div>
          {blockList.length < 1 ? (
            <Button htmlType="button" type="primary" onClick={addBlock}>
              {t('addService')}
            </Button>
          ) : null}
          <Button key="button" htmlType="button" type="primary" onClick={onSubmit}>
            {t('accept')}
          </Button>
        </div>
      }
    >
      <ul>
        {blockList.map((block, index) => (
          <li key={index}>
            <ScheduleLockItem
              startDate={block.from}
              endDate={block.to}
              onChange={onChangeItem(index)}
              addBlock={addBlock}
              removeBlock={removeBlock(index)}
            />
          </li>
        ))}
      </ul>
    </Modal>
  );
};

ModalAddBlokedDays.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
};

ScheduleLockItem.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func,
  addBlock: PropTypes.func,
  removeBlock: PropTypes.func,
};
