import React from 'react'
import WidgetsExternalCreate from '../../components/widgetsExternal/WidgetsExternalCreate'

const WidgetsExternal = () => {
  return (
   <>
   
   <WidgetsExternalCreate/>
   </>
  )
}

export default WidgetsExternal
