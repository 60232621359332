import PropTypes from 'prop-types';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import CustomListNode from './CustomListNode';
import { useListNodesActions } from './hooks/useListNodesActions';
import { PreventDragInteractiveElement } from '../../../../components/customDnD/sensors/preventDragInteractiveElement';

export function CustomListItems({ nodes, saveNodesChanges }) {
  const { addNode, updateNode, deleteNode } = useListNodesActions({ nodes, saveNodesChanges });
  const sensors = useSensors(
    useSensor(PreventDragInteractiveElement),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  );

  const onDragEnd = (event) => {
    const { active, over } = event;
    const activeIndex = nodes.findIndex((item) => item.id === active.id);
    const overIndex = nodes.findIndex((item) => item.id === over.id);
    const newItems = arrayMove(nodes, activeIndex, overIndex);
    saveNodesChanges(newItems);
  };

  const handleSetItemNodes = (node) => (newItemNodes) => {
    const newNodes = nodes.map((item) => {
      if (item.id === node.id) {
        return { ...item, nodes: newItemNodes };
      }
      return item;
    });
    saveNodesChanges(newNodes);
  };

  const saveNode = (nodeId, values) => {
    if (nodeId == null) {
      return addNode(values);
    }
    return updateNode(nodeId, values);
  };

  return (
    <DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter} sensors={sensors}>
      <div>
        <SortableContext items={nodes} strategy={verticalListSortingStrategy}>
          {nodes.map((item) => {
            const childChangesHandler = handleSetItemNodes(item);
            return (
              <CustomListNode
                key={item.id}
                nodeData={item}
                saveChanges={saveNode}
                removeNode={deleteNode}
                setChildNodes={childChangesHandler}
                renderChildNodes={() =>
                  item.nodes?.length > 0 ? (
                    <CustomListItems nodes={item.nodes} saveNodesChanges={childChangesHandler} />
                  ) : null
                }
              />
            );
          })}
        </SortableContext>
      </div>
    </DndContext>
  );
}

CustomListItems.propTypes = {
  nodes: PropTypes.array,
  saveNodesChanges: PropTypes.func,
};
