/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import FeatherIcon from 'feather-icons-react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './AppMenu';
// import MenueItems from './MenueItems';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo } from './style';
import AuthInfo from '../../components/utilities/auth-info/info';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../../redux/themeLayout/actionCreator';
import { WidgetsHtml } from '../../components/widgetsHtml/widgetsHtml';
import { MenuLogo } from '../component/menuLogo';

const { darkTheme } = require('../../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        customizerAction: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, customizerAction } = this.state;
      const { ChangeLayoutMode, rtl, changeRtl, changeLayout, topMenu, changeMenuMode } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = (forceCollapse) => {
        if (window.innerWidth <= 990 || forceCollapse) {
          this.setState({
            collapsed: forceCollapse ? true: !collapsed,
          });
        }
      };

      const showCustomizer = () => {
        this.setState({
          customizerAction: !customizerAction,
        });
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
          display: 'flex',
          flexDirection: 'column',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const onRtlChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'rtl');
        changeRtl(true);
      };

      const onLtrChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'ltr');
        changeRtl(false);
      };

      const modeChangeDark = () => {
        changeLayout(true);
      };

      const modeChangeLight = () => {
        changeLayout(false);
      };

      const modeChangeTopNav = () => {
        changeMenuMode(true);
      };

      const modeChangeSideNav = () => {
        changeMenuMode(false);
      };

      const onEventChange = {
        onRtlChange,
        onLtrChange,
        modeChangeDark,
        modeChangeLight,
        modeChangeTopNav,
        modeChangeSideNav,
      };

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <Row>
                <Col lg={!topMenu ? 4 : 3} sm={6} xs={12} className="align-center-v navbar-brand">
                  {!topMenu || window.innerWidth <= 991 ? (
                    <Button type="link" onClick={toggleCollapsed}>
                      <img src={require(`../../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
                    </Button>
                  ) : null}
                  <Link
                    className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                    to="/"
                  >
                    <MenuLogo />
                  </Link>
                </Col>

                <Col lg={!topMenu ? 14 : 15} md={8} sm={0} xs={0}>
                  {topMenu && window.innerWidth > 991 ? <TopMenu /> : null}
                </Col>

                <Col lg={6} md={10} sm={0} xs={0}>
                  <AuthInfo />
                </Col>
              </Row>
            </Header>
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    {/*
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                    <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                  */}
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={darkTheme}>
                  <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={renderTrackVertical}
                    >
                      <MenueItems
                        topMenu={topMenu}
                        rtl={rtl}
                        toggleCollapsed={toggleCollapsedMobile}
                        darkMode={darkMode}
                        events={onEventChange}
                      />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                  {/* <Footer className="admin-footer" style={footerStyle}>
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">2022 © Powered by Netux</span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink>
                        </div>
                      </Col>
                    </Row>
                  </Footer> */}
                </Content>
              </Layout>
            </Layout>
          </Layout>
          {/* <Link
            to="#"
            className="customizer-trigger"
            onClick={() => {
              showCustomizer();
            }}
          >
            <FeatherIcon icon="settings" />
          </Link> */}

          <div className={`${customizerAction ? 'customizer-wrapper show' : 'customizer-wrapper'}`}>
            <div className="customizer">
              <div className="customizer__head">
                <h4 className="customizer__title">Customizer</h4>
                <span className="customizer__sub-title">Customize your overview Page layout</span>
                <Link
                  to="#"
                  className="customizer-close"
                  onClick={() => {
                    showCustomizer();
                  }}
                >
                  <FeatherIcon icon="x" />
                </Link>
              </div>
              <div className="customizer__body">
                <div className="customizer__single">
                  <h4>Layout Type</h4>
                  <ul className="customizer-list d-flex">
                    <li className="customizer-list__item">
                      <Link
                        className={!rtl ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          onLtrChange();
                        }}
                        to="#"
                      >
                        <img src={require('../../static/img/ltr.png')} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                    <li className="customizer-list__item">
                      <Link
                        className={rtl ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          onRtlChange();
                        }}
                        to="#"
                      >
                        <img src={require(`../../static/img/rtl.png`)} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="customizer__single">
                  <h4>Sidebar Type</h4>
                  <ul className="customizer-list d-flex">
                    <li className="customizer-list__item">
                      <Link
                        className={!darkMode ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          modeChangeLight();
                        }}
                        to="#"
                      >
                        <img src={require('../../static/img/light.png')} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                    <li className="customizer-list__item">
                      <Link
                        className={darkMode ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          modeChangeDark();
                        }}
                        to="#"
                      >
                        <img src={require(`../../static/img/dark.png`)} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="customizer__single">
                  <h4>Navbar Type</h4>
                  <ul className="customizer-list d-flex">
                    <li className="customizer-list__item">
                      <Link
                        className={!topMenu ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          modeChangeSideNav();
                        }}
                        to="#"
                      >
                        <img src={require('../../static/img/side.png')} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                    <li className="customizer-list__item top">
                      <Link
                        className={topMenu ? 'active' : 'deactivate'}
                        onClick={() => {
                          showCustomizer();
                          modeChangeTopNav();
                        }}
                        to="#"
                      >
                        <img src={require(`../../static/img/top.png`)} alt="" />
                        <FontAwesome name="check-circle" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <span
            type="button"
            className={`${customizerAction ? 'overlay-dark show' : 'overlay-dark'}`}
            onClick={() => {
              showCustomizer();
            }}
          />
          <WidgetsHtml />
        </Div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  const mapStateToDispatch = (dispatch) => {
    return {
      changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
      changeLayout: (show) => dispatch(changeLayoutMode(show)),
      changeMenuMode: (show) => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
