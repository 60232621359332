const actions = {
  ACCOUNT_VALIDATION_BEGIN: 'ACCOUNT_VALIDATION_BEGIN',
  ACCOUNT_VALIDATION_SUCCESS: 'ACCOUNT_VALIDATION_SUCCESS',
  ACCOUNT_VALIDATION_ERR: 'ACCOUNT_VALIDATION_ERR',
  accountValidationBegin: () => {
    return {
      type: actions.ACCOUNT_VALIDATION_BEGIN,
    };
  },
  accountValidationSuccess: data => {
    return {
      type: actions.ACCOUNT_VALIDATION_SUCCESS,
      data,
    };
  },
  accountValidationErr: err => {
    return {
      type: actions.ACCOUNT_VALIDATION_ERR,
      err,
    };
  },
};

export default actions;
