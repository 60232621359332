import Styled from 'styled-components';

const UserInfoCardContainer = Styled.div`
padding: 10px;
margin-top:${(props) => props.margin};
background-color:${(props) => `${props.variant}30`};
border-radius: 5px;
min-height:60px;
.title{
color:${(props) => `${props.variant}`};
font-size:15px;
margin-bottom:0px
};
.text{
color:${(props) => `${props.variant}`};
font-size:12px;
}

`;

export { UserInfoCardContainer };
