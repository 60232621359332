const actions = {
  CUSTOM_REPORTS_BEGIN: 'CUSTOM_REPORTS_BEGIN',
  CUSTOM_REPORTS_SUCCESS: 'CUSTOM_REPORTS_SUCCESS',
  CUSTOM_REPORTS_ERR: 'CUSTOM_REPORTS_ERR',

  customReportsBegin: () => {
    return {
      type: actions.CUSTOM_REPORTS_BEGIN,
    };
  },

  customReportsSuccess: data => {
    return {
      type: actions.CUSTOM_REPORTS_SUCCESS,
      data,
    };
  },

  customReportsErr: err => {
    return {
      type: actions.CUSTOM_REPORTS_ERR,
      err,
    };
  },
};

export default actions;
