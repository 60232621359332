import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

export class UtilsService {
  static async getColors() {
    const { data } = await DataService.get(`${API.utils.root}/${API.utils.colors}`);
    return {
      colorList: data.colorList,
    };
  }

  static async getLanguages() {
    const { data } = await DataService.get(`${API.utils.root}/${API.utils.languages}`);
    return {
      languages: data.languages,
    };
  }

  static async getTimezones({ search, page }) {
    const params = { search, page }
    const { data } = await DataService.get(`${API.utils.root}/${API.utils.timezones}`, params);
    return {
      timezones: data.timezones,
      total: data.total,
    };
  }
}
