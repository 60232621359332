import styled from 'styled-components';

const ContentItem = styled.div`
  display: flex;
  background: #F5F7FD;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  border: solid 1px #ebebf7;
  border-radius: 4px;
  margin: 10px 5px;
  padding: 5px 15px;
  min-height: 40px;
  Button {
    border: none;
    margin: 0;
    width: 24px;
    background: #F5F7FD;
    &:hover, &:focus, &:active {
      background: #F5F7FD;
    }
  }

  .iconDrag {
    color: #acb4d1;
    font-size: 20px;
  }
`;

const ContenChilNode = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #acb4d1;
  min-height: 40px;
  margin-left: 30px;

  Button {
    border: none;
  }

  .iconDrag {
    color: #acb4d1;
    font-size: 10px;
  }
`;

const NodeName = styled.span`
  marginLeft: 5px;
  font-weight: bold;
`;

const ChildName = styled.h4`
  margin: 0;
  font-weight: bold;
`;

const ItemsCrud = styled.div`
  display: flex;
  margin-left: auto;
  gap: 5px;
  Button {
    color: #acb4d1;
    border: none;
  }
`;

const ChildNodesWrapper = styled.div`
  margin-left: 20px;
`;

export { ContentItem, NodeName, ItemsCrud, ChildName, ContenChilNode, ChildNodesWrapper };
