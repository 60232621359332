import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useChatUpdates } from './hooks/useChatUpdates';
import { ChatUpdatesDecoratorContainer } from '../style';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';

function Decorator({ title, background }) {
  const { translate: t } = useDictionaryTranslation();
  return (
    <Tooltip title={t(title)}>
      <ChatUpdatesDecoratorContainer background={background}>
        <FeatherIcon icon="bell" size={14} className="icon" />
      </ChatUpdatesDecoratorContainer>
    </Tooltip>
  );
}

export function ChatUpdateDecorator() {
  const chatUpdates = useChatUpdates();

  if (chatUpdates.hasUnassignedChats) {
    return <Decorator title="thereAreUnassignedChats" background="#FF3300" />;
  }

  if (chatUpdates.hasAssignedChats) {
    return <Decorator title="thereAreActiveChats" background="#FF9966" />;
  }

  return null;
}

Decorator.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};
