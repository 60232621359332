const timeFormat = {
  TWELVE_HOURS_CLOCK: { value: 1, label: 'nHours', time: 12 },
  MILITARY_TIME: { value: 2, label: 'nHours', time: 24 },
};

const selfSchedulingTimeUnits = {
  MINUTES: { value: 1, label: 'minutes' },
  HOURS: { value: 2, label: 'hours' },
};

const limitTimeUnits = {
  DAYS: { value: 1, label: 'days' },
  MONTHS: { value: 2, label: 'months' },
};

const lengthValues = {
  MINUTES_10: { value: 1, label: 'nMinutes', timeUnit: selfSchedulingTimeUnits.MINUTES.value, timeValue: 10 },
  MINUTES_20: { value: 2, label: 'nMinutes', timeUnit: selfSchedulingTimeUnits.MINUTES.value, timeValue: 20 },
  MINUTES_30: { value: 3, label: 'nMinutes', timeUnit: selfSchedulingTimeUnits.MINUTES.value, timeValue: 30 },
  MINUTES_45: { value: 4, label: 'nMinutes', timeUnit: selfSchedulingTimeUnits.MINUTES.value, timeValue: 45 },
  MINUTES_60: { value: 5, label: 'nMinutes', timeUnit: selfSchedulingTimeUnits.MINUTES.value, timeValue: 60 },
  CUSTOM: { value: 6, label: 'custom' },
};

const days = [
  { value: 1, label: 'monday' },
  { value: 2, label: 'tuesday' },
  { value: 3, label: 'wednesday' },
  { value: 4, label: 'thursday' },
  { value: 5, label: 'friday' },
  { value: 6, label: 'saturday' },
  { value: 0, label: 'sunday' },
];

const SELF_SCHEDULING_TOKEN_KEY = 'patient_self_scheduling_token';

const defaultLengthData = {
  type: lengthValues.MINUTES_10.value,
  timeValue: lengthValues.MINUTES_10.timeValue,
  timeUnit: lengthValues.MINUTES_10.timeUnit,
};

export {
  defaultLengthData,
  selfSchedulingTimeUnits,
  lengthValues,
  days,
  timeFormat,
  SELF_SCHEDULING_TOKEN_KEY,
  limitTimeUnits,
};
