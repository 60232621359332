import { Button } from 'antd';
import styled from 'styled-components';

const ButtonAddKey = styled(Button)`
  display: flex;
  background-color: #ea4d01;
  color: white;
  margin-bottom: 30px;
  height: 40px;
  border: none;
  span {
    margin: auto;
  }
`;



const ApiMessage = styled.h3``;

const Tokencontainer = styled.div`
  border-radius: 10px;
  border: 2px #d6dce3 solid;
  background-color: #f4f5f7;
  padding: 10px;
`;

const TokenText = styled.span`
  border-radius: 10px;
  word-wrap: break-word;
  color: #aeb4c1;
`;

const CopyButton=styled(Button)`



`

export { ButtonAddKey, ApiMessage, TokenText, Tokencontainer ,CopyButton};
