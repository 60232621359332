import { useState } from "react";
import { Space, Radio } from 'antd';
import PropTypes from 'prop-types'
import { ProfileTypes } from "../../../config/constants/userConstants";
import { useDictionaryTranslation } from "../../../hooks/useDictionaryTranslation";
import { Button } from "../../../components/buttons/buttons";

export const UserFilterButtonContent = ({ onSelect }) => {
  const [radioValue, setRadioValue] = useState();
  const { translate: t } = useDictionaryTranslation();

  const onChange = (e) => {
    setRadioValue(e.target.value);
    onSelect(e.target.value);
  };

  const clearFilter = () => {
    setRadioValue();
    onSelect();
  }

  const listProfiles = [
    ProfileTypes.OPERATOR,
    ProfileTypes.TECHNICIAN,
    ProfileTypes.INTERESTED,
    ProfileTypes.ADMIN,
  ];

  const radioProfiles = listProfiles.map((item) => 
  <Radio value={item.id}>{t(item.name)}</Radio>
  );

  

  return (
    <>
      <Space size="small" direction="vertical">
        <Radio.Group onChange={onChange} value={radioValue}>
          <Space direction="vertical">
            {radioProfiles}
          </Space>

        </Radio.Group>
        <Button style={{ height: '25px', marginLeft: '30%' }} onClick={clearFilter} type="primary">
          {t('clear')}
        </Button>
      </Space>
    </>
  );
}

UserFilterButtonContent.propTypes = {
  onSelect: PropTypes.func,
}