import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useNotifications } from '../../../../../hooks/useNotifications';

export const useListNodesActions = ({ saveNodesChanges, nodes }) => {
  const { showSuccessNotification } = useNotifications();

  const updateNode = useCallback(
    async (id, nodeData) => {
      const newNodes = nodes.map((node) => (node.id === id ? { ...node, ...nodeData } : node));
      await saveNodesChanges(newNodes);
      showSuccessNotification({
        title: 'success',
        description: 'successUpdatingRecord',
      });
    },
    [saveNodesChanges, nodes, showSuccessNotification],
  );

  const addNode = useCallback(
    async (nodeData) => {
      const newNode = { id: uuid(), ...nodeData };
      const newNodes = [...nodes, newNode];
      await saveNodesChanges(newNodes);
      showSuccessNotification({
        title: 'success',
        description: 'successCreatingRecord',
      });
    },
    [saveNodesChanges, nodes, showSuccessNotification],
  );

  const deleteNode = useCallback(
    async (id) => {
      const newNodes = nodes.filter((node) => node.id !== id);
      await saveNodesChanges(newNodes);
      showSuccessNotification({
        title: 'success',
        description: 'successRemovingRecord',
      });
    },
    [saveNodesChanges, nodes, showSuccessNotification],
  );

  return {
    nodes,
    saveNodesChanges,
    addNode,
    updateNode,
    deleteNode,
  };
};
