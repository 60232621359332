import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';

export const PasswordInput = ({ label, name, required, disabled = false }) => {
  const { translate: t } = useDictionaryTranslation();

  const checkValue = (regexValidator) => (_, value) => {
    if (value?.length > 0 && !value.match(regexValidator)) return Promise.reject();
    return Promise.resolve();
  };

  const validators = [
    { validator: checkValue(/(.*[A-Z]){2}/), message: t('passwordUppercaseLetters', { n: 2 }) },
    {
      validator: checkValue(/.*[!@#$&*.-]/),
      message: t('passwordSpecialChars', { n: 1, acceptedChars: '!@#$&*.-', interpolation: { escapeValue: false } }),
    },
    { validator: checkValue(/(.*[0-9]){2}/), message: t('passwordDigits', { n: 2 }) },
    { validator: checkValue(/(.*[a-z]){2}/), message: t('passwordLowercaseLetters', { n: 2 }) },
    { validator: checkValue(/.{8,}/), message: t('passwordLength', { n: 8 }) },
  ];

  return (
    <Form.Item name={name} label={label} rules={[{ required, message: t('passwordRequiredMessage') }, ...validators]}>
      <Input.Password disabled={disabled} />
    </Form.Item>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
