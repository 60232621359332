import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../../hooks/useNotifications';
import { askForAuthCheck } from '../../../redux/authentication/actionCreator';
import { AdminService } from '../../../services/adminService';
import { AccountsService } from '../../../services/accountsService';

export function useAccountActions() {
  const dispatch = useDispatch();
  const { showErrorNotification } = useNotifications();

  const manageAccount = useCallback(
    async (accountId) => {
      try {
        await AdminService.manageAccount(accountId);
        dispatch(askForAuthCheck());
      } catch (error) {
        showErrorNotification({
          title: 'error',
          description: 'errorUpdatingRecord',
        });
      }
    },
    [showErrorNotification, dispatch],
  );

  const checkPrefixAvailability = useCallback(
    async (prefix, accountId) => {
      const isAvailable = await AccountsService.checkPrefixAvailability(prefix, accountId);
      return isAvailable;
    },
    [],
  );

  const deselectAccount = useCallback(() => {
    manageAccount(null);
  }, [manageAccount]);

  return { manageAccount, deselectAccount, checkPrefixAvailability };
}
