import PropTypes from 'prop-types';
import { SelectHeadquartersBase } from './SelectHeadquartersBase';
import { HeadquartersService } from '../../services/headquartersService';

export const SelectHeadquarters = ({
  multiple,
  value,
  onChange,
  getData,
  autoSelect,
  idsHeadquarters,
  useFilteredIds,
}) => {
  return (
    <SelectHeadquartersBase
      multiple={multiple}
      value={value}
      onChange={onChange}
      getData={getData}
      autoSelect={autoSelect}
      idsHeadquarters={idsHeadquarters}
      useFilteredIds={useFilteredIds}
    />
  );
};

SelectHeadquarters.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
  getData: PropTypes.func,
  autoSelect: PropTypes.bool,
  idsHeadquarters: PropTypes.array,
  useFilteredIds: PropTypes.bool,
};

SelectHeadquarters.defaultProps = {
  getData: HeadquartersService.getHeadquarters,
};
