const actions = {
  COLOR_LIST_BEGIN: 'COLOR_LIST_BEGIN',
  COLOR_LIST_SUCCESS: 'COLOR_LIST_SUCCESS',
  COLOR_LIST_ERR: 'COLOR_LIST_ERR',

  colorListBegin: () => {
    return {
      type: actions.COLOR_LIST_BEGIN,
    };
  },

  colorListSuccess: data => {
    return {
      type: actions.COLOR_LIST_SUCCESS,
      data,
    };
  },

  colorListErr: err => {
    return {
      type: actions.COLOR_LIST_BEGIN,
      err,
    };
  },
};

export default actions;
