import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';
import { ImportListDataService } from '../config/dataService/importListDataService';

class CustomListsService {
  static async getListRoot(listId) {
    const { data } = await DataService.get(`${API.customLists.root}/${listId}/${API.customLists.listRoot}`);
    return {
      listRoot: data.listRoot,
    };
  }

  static async getAllList({ search, page }) {
    const params = {
      page,
      search,
    };
    const { data } = await DataService.get(`${API.customLists.root}/`, params);
    return {
      customLists: data.customLists,
      pageSize: data.pageSize,
      page: data.page,
      total: data.total,
    };
  }

  static async getAllListNodes(listId) {
    const params = {
      paginate: false,
    };
    const { data } = await DataService.get(`${API.customLists.root}/${listId}/${API.customLists.listItems}`, params);
    return {
      listNodes: data.listItems,
      total: data.total,
    };
  }

  static async setListNodes(listId, nodes) {
    const params = {
      nodes,
    };
    const { data } = await DataService.put(`${API.customLists.root}/${listId}/${API.customLists.listItems}`, params);
    return {
      listNodes: data.nodes,
    };
  }

  static async addList({ title, description }) {
    const params = {
      title,
      description,
    };
    const { data } = await DataService.post(`${API.customLists.root}/`, params);
    return {
      customList: data.customList,
    };
  }

  static async editList(customListId, customListData) {
    const params = {
      title: customListData.title,
      description: customListData.description,
    };
    const { data } = await DataService.patch(API.customLists.root + customListId, params);

    return {
      customList: data.customList,
    };
  }

  static async removeList({ listId }) {
    const { data } = await DataService.delete(`${API.customLists.root}/${listId}`);
    return data;
  }

  static async getListItems(listId, parents = [], page = 1, searchValue = '') {
    const params = {
      page,
      parents,
      searchValue
    };
    const { data } = await DataService.get(`${API.customLists.root}/${listId}/${API.customLists.listItems}`, params);
    return {
      listItems: data.listItems,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async importCustomList({ idAccount, listId, listItemsFile, parentsIds }) {
    const { data } = await ImportListDataService.sendFile(API.importData.importCustomList, { idAccount, listId, listItemsFile, parentsIds });
    return {
      data
    }
  }

}

export { CustomListsService };
