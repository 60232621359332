const actions = {
  CUSTOM_HTML_BEGIN: 'CUSTOM_HTML_BEGIN',
  CUSTOM_HTML_SUCCESS: 'CUSTOM_HTML_SUCCESS',
  CUSTOM_HTML_ERR: 'CUSTOM_HTML_ERR',

  REMOVE_CUSTOM_HTML: 'REMOVE_CUSTOM_HTML',

  getCustomHtmlBegin: () => {
    return {
      type: actions.CUSTOM_HTML_BEGIN,
    };
  },

  getCustomHtmlSuccess: (data) => {
    return {
      type: actions.CUSTOM_HTML_SUCCESS,
      data,
    };
  },

  getCustomHtmlErr: (err) => {
    return {
      type: actions.CUSTOM_HTML_ERR,
      err,
    };
  },

  removeCustomHtml: (data) => {
    return {
      type: actions.REMOVE_CUSTOM_HTML,
      data,
    };
  },
};

export default actions;
