import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class SkillsService {

  static async getSkills({ page, search }) {
    const params = {
      page,
      search,
    }
    const { data } = await DataService.get(API.specialties.root, params)
    return {
      specialties: data.specialties,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async getSkillById(skillId) {
    const { data } = await DataService.get(`${API.specialties.root}/${skillId}`)
    return {
      specialty: data.specialty,
    };
  }

  static async createSkill( newData ) {
    const skillData = {
      name: newData.name,
      description: newData.description,
      idColor: newData.idColor,
    };
    const { data } = await DataService.post(API.specialties.root, skillData)
    return {
      specialty: data.specialty,
    };
  }

  static async updateSkill(id, newData) {
    const skillData = {
      _id: id,
      name: newData.name,
      description: newData.description,
      idColor: newData.idColor,
    };
    const { data } = await DataService.patch(`${API.specialties.root}/${id}`, skillData)
    return{
      specialty: data.specialty,
    }
  }

  static async removeSkills ( id ) {
    const { data } = await DataService.delete(`${API.specialties.root}/${id}`)
    return{
      specialty: data.specialty,
    };
  }
}


export { SkillsService };
