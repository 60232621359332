import { client } from './client';

const API_ENDPOINT = process.env.REACT_APP_MS_CUSTOM_LIST_IMPORTS;

class ImportListDataService {
  static sendFile(path = '', params) {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    })
    return client({
      method: 'POST',
      url: path,
      baseURL: API_ENDPOINT,
      data: formData,
    });
  }
}

export { ImportListDataService }