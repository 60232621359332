import { useState, useEffect, useCallback } from 'react';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { CustomListsService } from '../../../../../services/customListService';

const useCustomList = (customListId) => {
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [customListData, setCustomListData] = useState({
    title: '',
    description: '',
  });

  const createList = async (data) => {
    try {
      await CustomListsService.addList(data);
      showSuccessNotification({
        title: 'success',
        description: 'successCreatingRecord',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorCreatingRecord',
      });
      throw error;
    }
  };

  const updateList = async (data) => {
    try {
      await CustomListsService.editList(customListId, data);
      showSuccessNotification({
        title: 'success',
        description: 'successCreatingRecord',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorCreatingRecord',
      });
      throw error;
    }
  };

  const getcustomListData = useCallback(async () => {
    if (!customListId) return;
    setIsLoading(true)
    try {
      const data = await CustomListsService.getListRoot(customListId);
      setCustomListData(data.listRoot);
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRetrievingRecord',
      });
    }
    setIsLoading(false)
  }, [customListId, showErrorNotification]);

  useEffect(() => {
    getcustomListData();
  }, [getcustomListData]);

  return {
    createList,
    customListData,
    updateList,
    isLoading,
  };
};

export default useCustomList;
