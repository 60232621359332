import Styled from 'styled-components';
import { Input } from 'antd';


const AlignRight = Styled.div`
  display: flex;
  margin: 20px 0;
  padding: 0 16px;
  flex-direction: row-reverse;
`;

const ActionItem = Styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const FieldsContainer = Styled.div`
  font-size: 0.9em;
  strong {
    font-weight: 550;
  }
`;

const EditorInputContainer = Styled.div`
  height: 100%;
  & .ant-form-item, .ant-form-item-control, .ant-form-item-control-input, .ant-form-item-control-input-content {
    height: 100%;
  }
`;

const NotificationEditorContent = Styled.div`
  padding: 0 16px;
`;

const InputFormAnt = Styled(Input)`
height: 40px;

`


export { AlignRight, ActionItem, FieldsContainer, EditorInputContainer, NotificationEditorContent,InputFormAnt };
