import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from './DropdownSelect';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';

export const SelectFromConstant = ({ placeholderText, constantObject, value, onChange, multiple = false , disabled}) => {
  const { translate: t } = useDictionaryTranslation();
  const options = useMemo(() => {
    return Object.values(constantObject).map((v) => ({ ...v, label: t(v.label) }));
  }, [constantObject, t]);

  const [filteredOptions, setFilteredOptions] = useState(options);

  const onSearch = useCallback(
    (searchValue) => {
      const regexp = new RegExp(searchValue, 'ig');
      setFilteredOptions(options.filter((option) => option.label.match(regexp)));
    },
    [options],
  );

  return (
    <DropdownSelect
      onChange={onChange}
      placeholderText={t(placeholderText)}
      options={filteredOptions}
      value={value}
      onSearch={onSearch}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

SelectFromConstant.propTypes = {
  placeholderText: PropTypes.string,
  disabled: PropTypes.bool,
  constantObject: PropTypes.object,
  multiple: PropTypes.bool,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};
