import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { FormWrapper } from '../../../../components/formWrapper/form-wrapper';
import { noEmptyStringValidator } from '../../../../utility/forms';

export const ModalEditNode = ({ nodeData, show, handleClose, saveNode }) => {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();
  const [isLoadingnodeListData, setIsLoadingNodeListData] = useState(false);

  const closeModal = ({ refreshData = false }) => {
    form.resetFields();
    handleClose(refreshData);
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    setIsLoadingNodeListData(true);
    try {
      await saveNode(nodeData?.id, values);
      closeModal({ refreshData: true });
    } finally {
      setIsLoadingNodeListData(false);
    }
  };

  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        title: nodeData?.title,
      });
    }
  }, [show]);

  return (
    <Modal
      title={t(nodeData?.id ? 'updateListNode' : 'createListNode')}
      centered
      destroyOnClose
      width="30%"
      height="70%"
      onCancel={closeModal}
      visible={show}
      footer={
        <div>
          <Button form="createListNode" key="submit" htmlType="submit" type="primary" disabled={isLoadingnodeListData}>
            {t('save')}
          </Button>
        </div>
      }
    >
      <FormWrapper>
        <Form name="createListNode" layout="vertical" form={form} onFinish={handleSubmit}>
          <Row>
            <Col xs={24} md={24}>
              <Form.Item
                name="title"
                rules={[{ validator: noEmptyStringValidator, required: true, message: t('nameRequired') }]}
                label={t('name')}
              >
                <Input placeholder={t('namePlaceholder')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormWrapper>
    </Modal>
  );
};

ModalEditNode.propTypes = {
  show: PropTypes.bool,
  nodeData: PropTypes.object,
  handleClose: PropTypes.func,
  saveNode: PropTypes.func,
};
