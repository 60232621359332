import Styled from 'styled-components';

const EditorContainer = Styled.div`
  height: 100%;
  & .cm-theme, .cm-editor {
    height: 100%;
  }
`;

export { EditorContainer };
