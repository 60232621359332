import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import useCustomList from './hooks/useCustomList';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { FormWrapper } from '../../../../components/formWrapper/form-wrapper';
import { noEmptyStringValidator } from '../../../../utility/forms';

export const ModalEditList = ({ customListId, show, handleClose }) => {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();
  const [isLoadingcustomListData, setIsLoadingcustomListData] = useState(false);
  const { createList, customListData,updateList } = useCustomList(customListId);

  const closeModal = ({ refreshData = false }) => {
    form.resetFields();
    handleClose(refreshData);
  };

  const getFormData = () => {
    const values = form.getFieldsValue();
    return {
      title: values.title,
      description: values.description,
    };
  };




  const handleSubmit = async () => {
    const data = getFormData();
    setIsLoadingcustomListData(true);
    try {
      if (customListId) await updateList(data);
      else await createList(data);
      closeModal({ refreshData: true });
    } finally {
      setIsLoadingcustomListData(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      title: customListData.title,
      description: customListData.description,
    });
  }, [customListData]);

  return (
    <Modal
      title={t(customListId ? 'updateList' : 'createList')}
      centered
      destroyOnClose
      width="30%"
      height="70%"
      onCancel={closeModal}
      visible={show}
      footer={
        <div>
          <Button form="createList" key="submit" htmlType="submit" type="primary" disabled={isLoadingcustomListData}>
            {t('save')}
          </Button>
        </div>
      }
    >
      <Spin spinning={isLoadingcustomListData}>
        <FormWrapper>
          <Form name="createList" layout="vertical" form={form} onFinish={handleSubmit}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item
                  name="title"
                  rules={[{ validator: noEmptyStringValidator, required: true, message: t('nameIsRequired') }]}
                  label={t('name')}
                >
                  <Input placeholder={t('namePlaceholder')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  name="description"
                  rules={[{ validator: noEmptyStringValidator, required: false, message: t('nameIsRequired') }]}
                  label={t('description')}
                >
                  <Input placeholder={t('')} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

ModalEditList.propTypes = {
  show: PropTypes.bool,
  customListId: PropTypes.string,
  handleClose: PropTypes.func,
};
